import React from "react";
import { PortWidget } from "@projectstorm/react-diagrams-core";
import { NodeModel, DefaultPortModel } from "@projectstorm/react-diagrams";
import { AbstractReactFactory } from "@projectstorm/react-canvas-core";
import { Box, ListItemIcon, Stack, ListItemText, Typography } from '@mui/material'

import Button from '@mui/material/Button';

import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';

import Header from "../../Header";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import FileSchemaDataService from "../../../service/FileSchemaService";
import TextField from '@mui/material/TextField';

import TableViewIcon from '@mui/icons-material/TableView';



export const SelectNodeWidget = props => {
    return (
        <div className="node" >
            {/*<div >*/}
            {/*    <div className="my-icon" />*/}
            {/*    <div className="node-header-text">Select</div>*/}
            {/*</div>*/}

            <PortWidget
                className="port-container left-port"
                engine={props.engine}
                port={props.node.getPort("in")}
            >
                <div className="node-port" />
            </PortWidget>

            {/*<div className="node-content">*/}
            {/*    <Box>*/}
            {/*        <TableViewIcon sx={{ fontSize: 40 }} />*/}
            {/*    </Box>*/}
            {/*</div>*/}


            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexShrink: '0',
                boxShadow: '0 .5rem 1rem rgba(0,0,0,.15)!important',
                padding: '5px',
                backgroundColor: 'white',
                border: '5px solid #a4a9fc'
            }}>
                <TableViewIcon
                    width="65px"
                    height="65px"
                    sx={{ width: "65px", height: '65px' }}
                />
            </Box>
            <Box className="label-name">{props.node.options.name}</Box>

            <PortWidget
                className="port-container right-port"
                engine={props.engine}
                port={props.node.getPort("out")}
            >
                <div className="node-port" />
            </PortWidget>
        </div>
    );
};


export class SelectModel extends NodeModel {
    constructor(options) {
        super({
            id: options?.id,
            name: options?.name || "Select",
            type: "Select"
        });
        this.depth = 0;
        this.precendentNodes = new Set();
        this.inputFields = new Array();
        this.outputFields = new Array();
        this.nodedata = options?.nodedata;

        if (options) {
            this.color = options.color || "black";
        }

        // setup an in and out port
        this.addPort(
            new DefaultPortModel({
                in: true,
                name: "in"
            })
        );
        this.addPort(
            new DefaultPortModel({
                in: false,
                name: "out"
            })
        );
    }

    serialize() {
        return {
            ...super.serialize(),
            depth: this.depth,
            inputFields : this.inputFields,
            outputFields: this.outputFields,
            name: this.options.name,
            precendentNodes: Array.from(this.precendentNodes),
            nodedata: this.nodedata,
        }
    }

    deserialize(event, engine) {
        super.deserialize(event, engine);
        this.depth = event.data.depth;
        this.inputFields = event.data.inputFields;
        this.outputFields = event.data.outputFields;
        this.nodedata = event.data.nodedata;
        this.options.name = event.data.name;
        this.precendentNodes = new Set(event.data.precendentNodes);
        this.options.selected = false;
    }


}

// TODO: Refactor to hooks
export class SelectNodeFactory extends AbstractReactFactory {
    constructor() {
        super("Select");
    }

    generateModel(initialConfig) {
        return new SelectModel();
    }

    generateReactWidget(event) {
        return <SelectNodeWidget engine={this.engine} node={event.model} />;
    }
}


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};


export function SelectComponent(props) {

    const currentComponent = props.currentComponent;
    const currentConversionstatuskey = props.currentConversionstatuskey;

    const [selected, setSelected] = React.useState([]);

    const [selectedColumns, setSelectedColumns] = React.useState([]);

    const [fileColumns, setFileColumns] = React.useState([]);

    const [nodeName, setNodeName] = React.useState('');
    const [init, setInit] = React.useState(false);

   
    React.useEffect(() => {
        setInit(true);
    }, []);

    React.useEffect(() => {
        
        if (!init) return;
        if (currentComponent.options && currentComponent.options.name) {
            setNodeName(currentComponent.options.name)
        }

        if (currentComponent.nodedata && currentComponent.nodedata.selectedColumns) {
            setSelectedColumns(currentComponent.nodedata.selectedColumns)
        }
        const parentsNodes = Object.values(props.currentComponent.ports['in'].links).map(link => link.sourcePort.parent);
        if (parentsNodes) {
            parentsNodes.forEach(parent => {
                if (parent.outputFields) {
                    let outputFields = JSON.parse(JSON.stringify(parent.outputFields));
                    if (currentConversionstatuskey == 500) {
                        const maxId = outputFields.reduce((max, item) => {
                            return item.id > max ? item.id : max;
                        }, -Infinity);

                        outputFields.push({ id: maxId + 1, FieldName: "saimfilestartdtkey" });
                        outputFields.push({ id: maxId + 2, FieldName: "saimfileenddtkey" });
                    }
                    props.currentComponent.inputFields = outputFields;
                }
            })
        }

        if (props.currentComponent.inputFields) {
            setFileColumns(props.currentComponent.inputFields);
        }

    }, [init]);

    const handleOnOpen = (index) => {
        const newSelectedColumns = [...selectedColumns];
        newSelectedColumns[index].open = true;
        setSelectedColumns(newSelectedColumns);
        console.log("calling handleOnOpen")
    } 

    const handleOnClose = (index) => {
        const newSelectedColumns = [...selectedColumns];
        newSelectedColumns[index].open = false;
        setSelectedColumns(newSelectedColumns);
        console.log("calling handleOnClose")
    } 

    const handleInputFileChange = (event) => {
        const value = event.target.value;

        if (value[value.length - 1] === "all") {
            setSelected(selected.length === fileColumns.length ? [] : fileColumns.map(columns => columns.FieldName));
            return;
        }
        setSelected(value);

        if (currentComponent.nodedata) {
            currentComponent.nodedata.selectedColumns = event.target.value;
        } else {
            currentComponent.nodedata = { selectedColumns: event.target.value }
        }
        
    }

    const handleAddSelectedColumn = (event) => {
        setSelectedColumns(selectedColumns => [...selectedColumns, { newName: '', column: ''}])
    };


    const handleDeleteSelectedColumn = (event, id) => {
        const newFields = selectedColumns.filter((column, index) => index !== id);
        currentComponent.nodedata.selectedColumns = newFields;
        setSelectedColumns(newFields);
        const outputFields = new Array();
        currentComponent.nodedata.selectedColumns.forEach(selectedColumn => {
            if (selectedColumn.newName) {
                outputFields.push({ id: outputFields.length + 1, FieldName: selectedColumn.newName })
            } else {
                outputFields.push({ id: outputFields.length + 1, FieldName: selectedColumn.column })
            }
        });
        currentComponent.outputFields = outputFields;
    };

    const handleAddAllClick = (event) => {
            const inputFile = Object.values(currentComponent.ports['in'].links)
                .map(link => link.sourcePort.parent)
            .map(parent => parent.nodedata);
            console.log(inputFile)
            if (inputFile.length > 0 && inputFile[0] && inputFile[0].clientfileschemakey) {
                FileSchemaDataService.getFileSchemaByClientFileSchemaKey(inputFile[0].clientfileschemakey)
                    .then((res) => {

                        const columns = res.data.map((data, index) => {
                            return {
                                id: index,
                                FieldOrder: data.fieldorder,
                                FieldName: data.fieldname,
                                FieldDesc: data.fielddesc,
                                FieldFormat: data.fieldformatdesc
                            }
                        })
                        setSelectedColumns(columns.map(col => { return { newName: '', column: col.FieldName} }))
                        setFileColumns(columns);
                    })
                    .catch(err => {
                        console.log(err);
                    })
            }

        getFileSchemaByFileConfigKey();
            
    }

    const getFileSchemaByFileConfigKey = () => {
        const inputFile = Object.values(currentComponent.ports['in'].links)
            .map(link => link.sourcePort.parent)
            .map(parent => parent.nodedata);
        if (inputFile.length > 0 && inputFile[0] && inputFile[0].fileConfigKey) {
            FileSchemaDataService.getFileSchemaByFileConfigKey(inputFile[0].fileConfigKey)
                .then((res) => {

                    if (res.data && res.data.parquetfileschema) {

                        let fileschema = JSON.parse(res.data.parquetfileschema);
                        let arr = fileColumns;
                        fileschema.forEach(x => {
                            let existingFieldName = fileColumns.some(item => item.FieldName === x.fieldname);
                            if (!existingFieldName) {
                                let newId = fileColumns.length > 0 ? Math.max(...fileColumns.map(item => item.id)) + 1 : 1;
                                let newObject = {
                                     id: newId,
                                    FieldOrder: "",
                                    FieldName: x.fieldname,
                                    FieldDesc: '',
                                    FieldFormat:""
                                };
                                arr.push(newObject);
                            }
                        });

                        setFileColumns(arr);
                        console.log(arr);
                    }
                  
                })
                .catch(err => {
                    console.log(err);
                })
        }

    }


    return (

        <Stack direction="row" spacing={5} width={"50%"}>
        <Box spacing={5} width={"50%"}>
                <FormControl style={{ minWidth: 300, maxWidth: 300 }}>
                    <TextField
                        autoComplete='off'
                        label="Node Name"
                        value={nodeName}
                        onChange={(event) => {
                            setNodeName(event.target.value)
                            currentComponent.options.name = event.target.value;
                        }}
                    />
                </FormControl>

                <br />
                <Stack direction="row" spacing={2}  >
                <Typography variant="h5" gutterBottom>
                    1. Select columns
                </Typography>
              
                    <Button variant="contained" color="primary" onClick={handleAddAllClick}>
                        Add All Columns
                    </Button>
                </Stack>

                <br />

          {selectedColumns.map((selectedColumn, index) => (
            <Stack direction="row" spacing={2} width={"50%"}>
                  <FormControl style={{ minWidth: 300, maxWidth: 300 }}>
                      <InputLabel id={ "selected-column-select-input-label-"+index}>Selected Column</InputLabel>
                    <Select
                          labelId={"selected-column-select-input-label-" + index}
                          id={"selected-column-select-label-" + index}
                          open={selectedColumn.open || false}
                          onClose={() => handleOnClose(index)}
                          onOpen={() => { handleOnOpen(index)}}
                        label="selected Column"
                        value={selectedColumn.column}
                        onChange={(event) => {
                            const newColumns = [...selectedColumns];
                            const newColumn = newColumns[index]
                            newColumn.column = event.target.value
                            setSelectedColumns(newColumns);
                            if (currentComponent.nodedata) {
                                currentComponent.nodedata.selectedColumns = newColumns
                            } else {
                                currentComponent.nodedata = { selectedColumns: newColumns }
                            }

                            const outputFields = new Array();
                            currentComponent.nodedata.selectedColumns.forEach(selectedColumn => {
                                if (selectedColumn.newName) {
                                    outputFields.push({ id: outputFields.length+1, FieldName: selectedColumn.newName })
                                } else {
                                    outputFields.push({ id: outputFields.length + 1, FieldName: selectedColumn.column })
                                }
                            });
                            currentComponent.outputFields = outputFields;

                        }}
                    >
                        {fileColumns.map(column => (
                            <MenuItem key={column.id} value={column.FieldName}>
                                {column.FieldName}
                            </MenuItem>
                        ))}
                    </Select>
                    <br />
                </FormControl>

                  <FormControl style={{ minWidth: 300, maxWidth: 300 }}>
                    <TextField
                        disableAutoFocus='true'
                        autoComplete='off'
                        label="New Column Name"
                        value={selectedColumn.newName}
                        onChange={(event) => {
                            const newColumns = [...selectedColumns];
                            const column = newColumns[index]
                            column.newName = event.target.value
                            setSelectedColumns(newColumns)

                            if (currentComponent.nodedata) {
                                currentComponent.nodedata.selectedColumns = newColumns
                            } else {
                                currentComponent.nodedata = { selectedColumns: newColumns }
                            }

                            const outputFields = new Array();
                            currentComponent.nodedata.selectedColumns.forEach(selectedColumn => {
                                if (selectedColumn.newName) {
                                    outputFields.push({ id: outputFields.length + 1, FieldName: selectedColumn.newName })
                                } else {
                                    outputFields.push({ id: outputFields.length + 1, FieldName: selectedColumn.column })
                                }
                            });
                            currentComponent.outputFields = outputFields;

                        }}
                    />
                </FormControl>

                <IconButton aria-label="delete"
                    sx={{
                        minWidth: 20,
                        maxWidth: 20,
                    }} onClick={(event) => handleDeleteSelectedColumn(event, index)}>
                    <ClearIcon />
                </IconButton>

            </Stack>
        ))
        }

        <IconButton aria-label="Add"
            sx={{
                minWidth: 30,
                maxWidth: 30,
            }} 
            onClick={handleAddSelectedColumn}>
            <AddIcon />
        </IconButton>

            </Box>
        </Stack>
        )
}
