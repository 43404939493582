
const serverVars = {
	apiUrl: 'https://audit.dev.saimedia.cloud/api',
	secApiUrl: 'https://audit.dev.saimedia.cloud/securityapi/api/v2'
};

const testVars = {
	apiUrl: 'https://audit.test.saimedia.cloud/api',
	secApiUrl: 'https://audit.test.saimedia.cloud/api'
};

const devVars = {
	apiUrl: 'https://audit.dev.saimedia.cloud/api',
	secApiUrl: 'https://audit.dev.saimedia.cloud/securityapi/api/v2'
};

const localVars = {
	apiUrl: 'https://localhost:5004/api',
	secApiUrl: 'https://localhost:7094/api/v2'
};


export function getConfiguration() {

	const env = localStorage.getItem("env") ?? "DEV";

	console.log("config, getConfiguration, env:", env);
	console.log("config, getConfiguration, REACT_APP_RUNTIME:", process.env.REACT_APP_RUNTIME);

	if (process.env.REACT_APP_RUNTIME === undefined) {

		if (env === 'DEV') {
			return devVars;
		}

		if (env === 'TEST') {
			return testVars;
		}
		if (env === 'PROD') {
			return serverVars;
		}
	}
	else {

		if (process.env.REACT_APP_RUNTIME === 'local') {
			return localVars;
		}

		if (process.env.REACT_APP_RUNTIME === 'TEST') {
			return testVars;
		}

		if (process.env.REACT_APP_RUNTIME === 'DEV') {
			return devVars;
		}

		if (process.env.REACT_APP_RUNTIME === 'PROD') {
			return serverVars
		}

		if (process.env.NODE_ENV === 'production') {
			return serverVars
		}
	}

	return serverVars;
}
