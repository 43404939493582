import React, { useState, useEffect } from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import {
    Autocomplete,
    Box,
    Button,
    Card,
    CardContent,
    CardActionArea,
    TextField,
    Stack,
    Checkbox,
    FormControlLabel,
    FormControl,
    IconButton,
    Tooltip,
    Chip,
    Modal,
    List,
    ListItem,
    ListItemText,
    Typography,
} from "@mui/material";
import ReportSection from "./ReportSection";
import AuditClientDataService from "../../service/AuditClientDataService";
import auditReportService from "../../service/AuditReportService";
import ContractDataService from "../../service/ContractDataService";
import FileConfigDataService from "../../service/FileConfigDataService";
import "./ReportWriterTab.css";

const reportItems = [
    {
        id: "notes",
        name: "Notes",
        auditreportitemdesckey: 1,
    },
    {
        id: "contract_terms",
        name: "Contract Terms",
        auditreportitemdesckey: 2,
    },
    {
        id: "data",
        name: "Data",
        auditreportitemdesckey: 3, 
    },
];

const ReportWriterTab = ({ currentAudit }) => {
    const [clientList, setClientList] = useState([]);
    const [networkList, setNetworkList] = useState([]);
    const [client, setClient] = useState(null);
    const [network, setNetwork] = useState(null);
    const [isNetworkClientSelected, setIsNetworkClientSelected] = useState(false);
    const [allSections, setAllSections] = useState([]);
    const [reportSectionList, setReportSectionList] = useState([]);
    const [currentTab, setCurrentTab] = useState(0);
    const [isEditActive, setIsEditActive] = useState(false);
    const [editorContent, setEditorContent] = useState("");
    const [auditConfigurationId, setAuditConfigurationId] = useState(null);
    const [auditReportKey, setAuditReportKey] = useState(null);
    const [isNewReport, setIsNewReport] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [contractTerms, setContractTerms] = useState([]);
    const [selectedContract, setSelectedContract] = useState(null);
    const [selectedContractTerms, setSelectedContractTerms] = useState([]);
    const [isDataModalOpen, setIsDataModalOpen] = useState(false);
    const [clientFiles, setClientFiles] = useState([]);
    const [isLoadingFiles, setIsLoadingFiles] = useState(false);
    const [fileFetchError, setFileFetchError] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [schemaColumns, setSchemaColumns] = useState([]);

    // Sample data for files
    const sampleFilesData = [
        {
            fileconfigkey: 1508,
            filenm: "U-verse_Emp_Acq_Deductions (1).xlsx",
            parquetfileschema:
                '[\n  {\n    "fieldname": "Count",\n    "dataType": "LongType()",\n    "nullable": true\n  },\n  {\n    "fieldname": "FeatureCode",\n    "dataType": "StringType()",\n    "nullable": true\n  },\n  {\n    "fieldname": "State",\n    "dataType": "StringType()",\n    "nullable": true\n  },\n  {\n    "fieldname": "VHO",\n    "dataType": "StringType()",\n    "nullable": true\n  },\n  {\n    "fieldname": "saimFileDtKey",\n    "dataType": "LongType()",\n    "nullable": true\n  },\n  {\n    "fieldname": "sourcefilekey",\n    "dataType": "LongType()",\n    "nullable": true\n  }\n]',
        },
        {
            fileconfigkey: 690,
            filenm: "Bravo Payment 2020-05.csv",
            parquetfileschema:
                '[\n  {\n    "fieldname": "c2",\n    "dataType": "StringType()",\n    "nullable": true\n  },\n  {\n    "fieldname": "c1",\n    "dataType": "StringType()",\n    "nullable": true\n  },\n  {\n    "fieldname": "sourcefilekey",\n    "dataType": "IntegerType()",\n    "nullable": true\n  }\n]',
        },
    ];

    useEffect(() => {
        if (currentAudit && currentAudit.audit) {
            AuditClientDataService.getClientNetworkByAuditKey(
                currentAudit.audit.auditkey
            )
                .then((res) => {
                    const clientNetworkOptions = res.data.items.map((item) => ({
                        clientKey: item.clientkey,
                        clientName: item.clientname,
                        networkKey: item.networkkey,
                        networkName: item.networkname,
                        auditConfigurationKey: item.auditconfigurationkey,
                    }));

                    // Create a map to group networks by clientKey
                    const clientsMap = new Map();

                    clientNetworkOptions.forEach((item) => {
                        const { clientKey, clientName } = item;
                        if (!clientsMap.has(clientKey)) {
                            clientsMap.set(clientKey, {
                                clientKey,
                                clientName,
                                networks: [],
                            });
                        }
                        clientsMap.get(clientKey).networks.push(item);
                    });

                    const uniqueClients = Array.from(clientsMap.values());

                    setClientList(uniqueClients);
                })
                .catch((err) => console.log(err));
        }
    }, [currentAudit]);

    useEffect(() => {
        auditReportService
            .getAuditReportSectionDesc()
            .then((res) => {
                setAllSections(
                    res.data.map((section) => ({
                        ...section,
                        isChecked: false,
                    }))
                );
            })
            .catch((err) => console.log(err));
    }, []);

    const modalStyle = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "80%",
        height: "70%",
        bgcolor: "background.paper",
        boxShadow: 24,
        p: 4,
        display: "flex",
        flexDirection: "row",
    };

    const handleAddDataReportItem = () => {
        if (!selectedFile) {
            return;
        }

        // Construct the rptColumnOrder JSON array
        const rptColumnOrderArray = schemaColumns.map((col, index) => ({
            columnName: col.fieldname.trim(),
            columnOrder: index + 1, 
        }));

        console.log('rptColumnOrderArray:', rptColumnOrderArray);

        const rptColumnOrderJson = JSON.stringify(rptColumnOrderArray);

        const newReportItem = {
            type: "data",
            value: `Selected File: ${selectedFile.filenm}`,
            isEdit: false,
            auditreportitemdesckey: reportItems[2].auditreportitemdesckey,
            itemOrder: reportSectionList[currentTab].reportList.length + 1,
            RptColumnOrder: rptColumnOrderJson, 
            fileConfigKey: selectedFile.fileconfigkey, 
        };
        console.log('New Report Item:', newReportItem);
        // Add the new report item to the current section
        const updatedList = [...reportSectionList];
        updatedList[currentTab].reportList.unshift(newReportItem);
        setReportSectionList(updatedList);

        setIsDataModalOpen(false);
    };

    const handleDataClick = async () => {
        if (!client) {
            // Handle the case where no client is selected
            console.warn("No client selected.");
            return;
        }

        FileConfigDataService.getFinalDataFilesWithClients(currentAudit.audit.auditkey, client.clientKey,network.networkKey)
        .then((res) => {
            console.log(res.data);

            let cFlies = res.data.map(x => {
                return {
                    fileconfigkey: x.fc.fileconfigkey,
                    filenm: x.fc.filenm,
                    parquetfileschema: x.fc.parquetfileschema
                }
            });
            console.log(res.data);
            console.log(cFlies);
            setClientFiles(cFlies);
        })
        .catch((err) => console.log(err));

        setIsLoadingFiles(true);
        setFileFetchError(null);
        setClientFiles(sampleFilesData);
        setIsDataModalOpen(true);
        setIsLoadingFiles(false);
    };

    const handleFileClick = (file) => {
        setSelectedFile(file);

        if (file.parquetfileschema) {
            try {
                // Parse the JSON string to an object
                const parsedSchema = JSON.parse(file.parquetfileschema);
                setSchemaColumns(parsedSchema);
            } catch (error) {
                console.error("Error parsing parquet file schema:", error);
                setSchemaColumns([]);
            }
        } else {
            // If no schema is available
            setSchemaColumns([]);
        }
    };

    const moveColumnUp = (index) => {
        if (index === 0) return; // Can't move the first item up
        setSchemaColumns((columns) => {
            const newColumns = [...columns];
            [newColumns[index - 1], newColumns[index]] = [
                newColumns[index],
                newColumns[index - 1],
            ];
            return newColumns;
        });
    };

    const moveColumnDown = (index) => {
        if (index === schemaColumns.length - 1) return; // Can't move the last item down
        setSchemaColumns((columns) => {
            const newColumns = [...columns];
            [newColumns[index], newColumns[index + 1]] = [
                newColumns[index + 1],
                newColumns[index],
            ];
            return newColumns;
        });
    };

    const handleAddContractTermAsNote = (term) => {
        if (
            reportSectionList.length === 0 ||
            currentTab >= reportSectionList.length
        ) {
            console.warn("No report section available to add the note.");
            return;
        }

        const noteContent = `
        Term Name: ${term.texttermname || "No name available"}
        Description: ${term.contracttermsdesc || "No description available"}
        Page Reference: ${term.textpageref || "No page reference"}
    `;

        const newNote = {
            type: "note",
            value: noteContent,
            isEdit: false,
            auditreportitemdesckey: reportItems[1].auditreportitemdesckey, 
            itemOrder: reportSectionList[currentTab].reportList.length + 1,
            contractTermsKey: term.contracttermskey,
        };

        const updatedList = [...reportSectionList];
        if (!updatedList[currentTab].reportList) {
            updatedList[currentTab].reportList = [];
        }
        updatedList[currentTab].reportList.unshift(newNote);
        setReportSectionList(updatedList);

        console.log("Contract term added as a note:", newNote);
    };

    const handleClientChange = (newValue) => {
        setClient(newValue ? newValue : null);
        setNetwork(null);
        setReportSectionList([]);
        setIsNetworkClientSelected(false);
        setAuditConfigurationId(null);

        if (newValue) {
            setNetworkList(newValue.networks);
        }
    };

    const handleNetworkChange = (newValue) => {
        setNetwork(newValue);
        setReportSectionList([]);
        setIsNetworkClientSelected(false);
        if (newValue) {
            setAuditConfigurationId(newValue.auditConfigurationKey);
        } else {
            setAuditConfigurationId(null);
        }
    };

    const handleContractTermsClick = async () => {
        try {
            const response = await ContractDataService.getContracts({
                clientkey: client?.clientKey,
                networkkey: network?.networkKey,
                auditkey: currentAudit.audit.auditkey,
            });
            console.log("Contracts fetched:", response.data);
            setContractTerms(response.data);
            setSelectedContract(null); // Clear selected contract on new fetch
            setSelectedContractTerms([]);
            handleOpenModal();
        } catch (error) {
            console.error("Error fetching contract terms:", error);
        }
    };

    const handleContractClick = (contract) => {
        console.log("Contract clicked:", contract);
        setSelectedContract(contract);

        // Log the contract terms for debugging
        const terms = contract.contractNetworkItems.flatMap(
            (item) => item.contractTermItems || []
        );
        console.log("Contract Terms:", terms);

        setSelectedContractTerms(terms);
    };

    const handleDropdownSelection = async () => {
        if (client && network) {
            setIsNetworkClientSelected(true);
            setReportSectionList([]);
            setCurrentTab(0);

            try {
                setAllSections((prevSections) =>
                    prevSections.map((section) => ({
                        ...section,
                        isChecked: false,
                        auditreportsectionkey: null,
                    }))
                );

                const sectionsResponse =
                    await auditReportService.GetAuditReportSectionsByConfig(
                        auditConfigurationId
                    );
                const configSections = sectionsResponse.data;

                const auditReportResponse =
                    await auditReportService.getAuditReportIdByConfig(
                        auditConfigurationId
                    );

                if (auditReportResponse.data) {
                    setAuditReportKey(auditReportResponse.data);
                    setIsNewReport(false);
                } else {
                    setAuditReportKey(null);
                    setIsNewReport(true);
                }

                const updatedSections = allSections.map((section) => {
                    const matchingConfigSection = configSections.find(
                        (configSection) =>
                            configSection.auditreportsectiondesckey ===
                            section.auditreportsectiondesckey
                    );

                    return {
                        ...section,
                        isChecked: !!matchingConfigSection,
                        auditreportsectionkey: matchingConfigSection
                            ? matchingConfigSection.auditreportsectionkey
                            : null,
                    };
                });

                setAllSections(updatedSections);

                const checkedSections = updatedSections.filter(
                    (section) => section.isChecked
                );
                const sectionsWithItems = await Promise.all(
                    checkedSections.map(async (section) => {
                        try {
                            const itemsResponse =
                                await auditReportService.getAuditReportItemsBySection(
                                    section.auditreportsectionkey
                                );
                            return {
                                ...section,
                                reportList: itemsResponse.data.map((item) => ({
                                    type: "note",
                                    value: item.itemcontent,
                                    isEdit: false,
                                    auditreportitemdesckey: item.auditreportitemdesckey,
                                    itemOrder: item.itemorder,
                                    auditreportitemkey: item.auditreportitemkey,
                                })),
                            };
                        } catch (error) {
                            console.error(
                                `Error fetching items for section ${section.auditreportsectiondesckey}:`,
                                error
                            );
                            return {
                                ...section,
                                reportList: [],
                            };
                        }
                    })
                );

                setReportSectionList(sectionsWithItems);
            } catch (error) {
                console.error("Error fetching sections and items:", error);
            }
        }
    };

    const handleSelectSection = (event, sectionKey) => {
        const isChecked = event.target.checked;

        setAllSections((prevSections) =>
            prevSections.map((section) =>
                section.auditreportsectiondesckey === sectionKey
                    ? { ...section, isChecked }
                    : section
            )
        );

        setReportSectionList((prevList) => {
            if (isChecked) {
                const sectionToAdd = allSections.find(
                    (section) => section.auditreportsectiondesckey === sectionKey
                );
                if (sectionToAdd) {
                    return [...prevList, { ...sectionToAdd, reportList: [] }];
                }
            } else {
                return prevList.filter(
                    (section) => section.auditreportsectiondesckey !== sectionKey
                );
            }
            return prevList;
        });
    };

    const handleAddEditor = () => {
        if (
            reportSectionList.length === 0 ||
            currentTab >= reportSectionList.length
        ) {
            return;
        }

        setIsEditActive(true);
        setEditorContent("");

        const updatedList = [...reportSectionList];
        if (!updatedList[currentTab].reportList) {
            updatedList[currentTab].reportList = [];
        }
        updatedList[currentTab].reportList.unshift({
            type: "note",
            value: "",
            isEdit: true,
            auditreportitemdesckey: reportItems[0].auditreportitemdesckey,
            itemOrder: updatedList[currentTab].reportList.length + 1,
        });
        setReportSectionList(updatedList);
    };

    const addNoteToReportSection = (description) => {
        if (
            reportSectionList.length === 0 ||
            currentTab >= reportSectionList.length
        ) {
            console.warn("No report section available to add the note.");
            return;
        }

        const updatedList = [...reportSectionList];
        if (!updatedList[currentTab].reportList) {
            updatedList[currentTab].reportList = [];
        }

        updatedList[currentTab].reportList.unshift({
            type: "note",
            value: description,
            isEdit: false,
            auditreportitemdesckey: reportItems[0].auditreportitemdesckey,
            itemOrder: updatedList[currentTab].reportList.length + 1,
        });

        setReportSectionList(updatedList);
    };

    const handleTermClick = (term) => {
        console.log("Term clicked:", term);

        const termDescription = term.termdesc || "No description available";

        addNoteToReportSection(termDescription);
    };

    const handleDeleteNote = (event, deleteIndex) => {
        event.stopPropagation();
        setIsEditActive(false);

        const updatedList = [...reportSectionList];
        if (updatedList[currentTab] && updatedList[currentTab].reportList) {
            updatedList[currentTab].reportList.splice(deleteIndex, 1);
            setReportSectionList(updatedList);
        }
    };

    const handleContentChange = (newContent) => {
        setEditorContent(newContent);
    };

    const handleDoneEdit = () => {
        setIsEditActive(false);

        if (editorContent && editorContent.replace(/\s/g, "") !== "<p><br></p>") {
            const updatedList = [...reportSectionList];
            if (updatedList[currentTab] && updatedList[currentTab].reportList) {
                updatedList[currentTab].reportList = updatedList[
                    currentTab
                ].reportList.map((row) => {
                    if (row.isEdit) {
                        return { ...row, value: editorContent, isEdit: false };
                    }
                    return row;
                });
                setReportSectionList(updatedList);
            }
            setEditorContent("");
        }
    };

    const handleChangeToEdit = (event, text, rowIndex) => {
        event.stopPropagation();
        if (!isEditActive) {
            const updatedList = [...reportSectionList];
            if (updatedList[currentTab] && updatedList[currentTab].reportList) {
                updatedList[currentTab].reportList = updatedList[
                    currentTab
                ].reportList.map((row, index) => ({
                    ...row,
                    isEdit: index === rowIndex,
                }));

                updatedList[currentTab].reportList = updatedList[
                    currentTab
                ].reportList.filter(
                    (item) =>
                        item.value !== "" &&
                        item.value.replace(/\s/g, "") !== "<p><br></p>"
                );

                setIsEditActive(true);
                setEditorContent(text);
                setReportSectionList(updatedList);
            }
        }
    };

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleReload = () => {
        // Reload the current report data
        if (client && network) {
            handleDropdownSelection();
        }
    };

    const handleLoadTemplate = () => {
        auditReportService.getAuditReportSectionDesc()
            .then((response) => {
                const defaultSections = response.data;

                // Update allSections to check those with isdefault=true
                setAllSections(prevSections =>
                    prevSections.map(section => {
                        const defaultSection = defaultSections.find(
                            ds => ds.auditreportsectiondesckey === section.auditreportsectiondesckey
                        );
                        return {
                            ...section,
                            isChecked: defaultSection?.isdefault || false,
                            auditreportsectionkey: defaultSection?.auditreportsectionkey
                        };
                    })
                );

                // Create report sections list with default sections and their default text
                const defaultReportSections = defaultSections
                    .filter(section => section.isdefault)
                    .map(section => ({
                        auditreportsectiondesckey: section.auditreportsectiondesckey,
                        auditreportsectionkey: section.auditreportsectionkey,
                        sectiontitle: section.sectiontitle,
                        sectionorder: section.sectionorder,
                        isChecked: true,
                        reportList: section.defaulttext ? [
                            {
                                type: "note",
                                value: section.defaulttext,
                                isEdit: false,
                                auditreportitemdesckey: reportItems[0].auditreportitemdesckey,
                                itemOrder: 1,
                            }
                        ] : []
                    }));

                // Update the report section list
                setReportSectionList(defaultReportSections);

                // Reset current tab to first tab
                setCurrentTab(0);
            })
            .catch((error) => {
                console.error("Error loading template:", error);
            });
    };

    return (
        <Box className="report-writer-tab">
            <Box
                className="report-writer-tab__audit-report"
                width={isNetworkClientSelected ? "50%" : "100%"}
            >
                <p className="audit-report-for">
                    <div>Audit Report for: &nbsp;</div>
                    <Stack direction="row" spacing={1}>
                        {client && <Chip label={client.clientName} />}
                        {network && <Chip label={network.networkName} />}
                    </Stack>
                </p>

                <Stack
                    direction="row"
                    spacing={2}
                    className="report-writer-tab_selector"
                >
                    <Autocomplete
                        name="client"
                        id="client"
                        value={client}
                        sx={{ width: 250 }}
                        onChange={(_, data) => handleClientChange(data)}
                        options={clientList}
                        isOptionEqualToValue={(option, value) =>
                            option.clientName === value.clientName
                        }
                        getOptionLabel={(option) => option.clientName}
                        renderInput={(params) => <TextField {...params} label="Client" />}
                        size="small"
                    />

                    <Autocomplete
                        name="network"
                        id="network"
                        value={network}
                        sx={{ width: 250 }}
                        onChange={(_, data) => handleNetworkChange(data)}
                        options={networkList}
                        disabled={!client}
                        isOptionEqualToValue={(option, value) =>
                            option.networkName === value.networkName
                        }
                        getOptionLabel={(option) => option.networkName}
                        renderInput={(params) => <TextField {...params} label="Network" />}
                        size="small"
                    />

                    <Button
                        color="primary"
                        variant="outlined"
                        disabled={!(client && network)}
                        onClick={handleDropdownSelection}
                    >
                        Select
                    </Button>

                    <Button
                        color="primary"
                        variant="outlined"
                        disabled={!isNetworkClientSelected}
                        onClick={handleReload}
                    >
                        Reload
                    </Button>

                    <Button
                        color="primary"
                        variant="outlined"
                        disabled={!isNetworkClientSelected}
                        onClick={handleLoadTemplate}
                    >
                        Load Template
                    </Button>

                </Stack>

                {isNetworkClientSelected && (
                    <Stack direction="row" spacing={4}>
                        <Box className="report-writer_sections">
                            <FormControl sx={{ width: "100%" }}>
                                <p className="sections-header">Sections:</p>
                                {allSections.map((section) => (
                                    <FormControlLabel
                                        key={section.auditreportsectiondesckey}
                                        control={
                                            <Checkbox
                                                checked={section.isChecked}
                                                onChange={(e) =>
                                                    handleSelectSection(
                                                        e,
                                                        section.auditreportsectiondesckey
                                                    )
                                                }
                                            />
                                        }
                                        label={section.sectiontitle}
                                        labelPlacement="start"
                                        style={{
                                            marginLeft: "0",
                                            display: "flex",
                                            justifyContent: "space-between",
                                        }}
                                    />
                                ))}
                            </FormControl>
                        </Box>

                        <FormControl className="report-writer__report-items">
                            <p className="report-items-header">Report Items:</p>
                            {reportItems.map((reportItem, index) => (
                                <div
                                    key={index}
                                    style={{ display: "flex", justifyContent: "space-between" }}
                                >
                                    <p style={{ fontWeight: "400", fontSize: "0.85rem" }}>
                                        {reportItem.name}
                                    </p>
                                    <Tooltip
                                        title={`Add ${reportItem.name} Content`}
                                        placement="right"
                                    >
                                        <IconButton
                                            color="primary"
                                            onClick={
                                                reportItem.id === "contract_terms"
                                                    ? handleContractTermsClick
                                                    : reportItem.id === "data"
                                                        ? handleDataClick
                                                        : handleAddEditor
                                            }
                                            disabled={
                                                isEditActive || reportSectionList.length === 0
                                            }
                                        >
                                            <ArrowForwardIcon fontSize="large" />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            ))}
                        </FormControl>
                    </Stack>
                )}

                {!isNetworkClientSelected && (
                    <div className="layout-empty">Select Client & Network</div>
                )}
            </Box>

            {isNetworkClientSelected && reportSectionList.length > 0 && (
                <ReportSection
                    client={client}
                    network={network}
                    reportSectionList={reportSectionList}
                    setReportSectionList={setReportSectionList}
                    currentTab={currentTab}
                    setCurrentTab={setCurrentTab}
                    handleDeleteNote={handleDeleteNote}
                    isEditActive={isEditActive}
                    handleDoneEdit={handleDoneEdit}
                    editorContent={editorContent}
                    handleContentChange={handleContentChange}
                    handleChangeToEdit={handleChangeToEdit}
                    auditReportKey={auditReportKey}
                    setAuditReportKey={setAuditReportKey}
                    currentAudit={currentAudit}
                    auditConfigurationId={auditConfigurationId}
                />
            )}

            {/* Contract Terms Modal */}
            <Modal
                open={isModalOpen}
                onClose={handleCloseModal}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box sx={modalStyle}>
                    <Box
                        sx={{
                            flex: 1,
                            pr: 2,
                            borderRight: "1px solid #ccc",
                            maxHeight: "400px",
                            overflowY: "auto",
                        }}
                    >
                        <Typography id="modal-title" variant="h6" gutterBottom>
                            Contracts
                        </Typography>
                        {contractTerms.length > 0 ? (
                            <ul>
                                {contractTerms.map((contract, index) => (
                                    <li
                                        key={index}
                                        onClick={() => handleContractClick(contract)}
                                        style={{ cursor: "pointer" }}
                                    >
                                        <strong>{contract.contractName}</strong> (Start Date:{" "}
                                        {contract.startDate}, End Date: {contract.endDate})
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <p>
                                No contracts available for the selected client and network.
                            </p>
                        )}
                    </Box>

                    {/* Scrollable Contract Terms */}
                    <Box
                        sx={{
                            flex: 1,
                            pl: 2,
                            maxHeight: "400px",
                            overflowY: "auto",
                        }}
                    >
                        <Typography variant="h6" gutterBottom>
                            Contract Terms
                        </Typography>
                        {selectedContractTerms.length > 0 ? (
                            selectedContractTerms.map((term, index) => (
                                <Card
                                    key={index}
                                    sx={{ marginBottom: "10px", cursor: "pointer" }}
                                    onClick={() => handleAddContractTermAsNote(term)} // Clicking adds the term as a note
                                >
                                    <CardActionArea>
                                        <CardContent>
                                            <Typography variant="subtitle1">
                                                <strong>Term Name:</strong>{" "}
                                                {term.texttermname
                                                    ? term.texttermname
                                                    : "No name available"}
                                            </Typography>
                                            <Typography variant="body2">
                                                <strong>Description:</strong>{" "}
                                                {term.contracttermsdesc
                                                    ? term.contracttermsdesc
                                                    : "No description available"}
                                            </Typography>
                                            <Typography variant="body2">
                                                <strong>Page Reference:</strong>{" "}
                                                {term.textpageref
                                                    ? term.textpageref
                                                    : "No page reference"}
                                            </Typography>

                                            {/* Displaying contract term type items */}
                                            {term.contractTermsTypeItems &&
                                                term.contractTermsTypeItems.length > 0 ? (
                                                term.contractTermsTypeItems.map((typeItem, idx) => (
                                                    <Box key={idx} sx={{ marginTop: 1 }}>
                                                        <Typography variant="body2">
                                                            <strong>Term Type:</strong>{" "}
                                                            {typeItem.contracttermstypename}
                                                        </Typography>
                                                        <Typography variant="body2">
                                                            <strong>Description:</strong>{" "}
                                                            {typeItem.contracttermsdesc
                                                                ? typeItem.contracttermsdesc
                                                                : "No description available"}
                                                        </Typography>
                                                    </Box>
                                                ))
                                            ) : (
                                                <Typography variant="body2">
                                                    No term type details available.
                                                </Typography>
                                            )}
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            ))
                        ) : (
                            <Typography>Select a contract to view its terms.</Typography>
                        )}
                    </Box>
                </Box>
            </Modal>

            {/* Data Modal */}
            <Modal
                open={isDataModalOpen}
                onClose={() => setIsDataModalOpen(false)}
                aria-labelledby="data-modal-title"
                aria-describedby="data-modal-description"
            >
                <Box sx={modalStyle}>
                    {/* Left Column: File List */}
                    <Box
                        sx={{
                            flex: 1,
                            pr: 2,
                            borderRight: "1px solid #ccc",
                            maxHeight: "80vh",
                            overflowY: "auto",
                        }}
                    >
                        <Typography id="data-modal-title" variant="h6" gutterBottom>
                            Data Files
                        </Typography>
                        {clientFiles.length > 0 ? (
                            <List>
                                {clientFiles.map((file, index) => (
                                    <ListItem
                                        button
                                        key={index}
                                        selected={
                                            selectedFile &&
                                            selectedFile.fileconfigkey === file.fileconfigkey
                                        }
                                        onClick={() => handleFileClick(file)}
                                    >
                                        <ListItemText primary={file.filenm} />
                                    </ListItem>
                                ))}
                            </List>
                        ) : (
                            <Typography>No files available.</Typography>
                        )}
                    </Box>

                    {/* Right Column: Schema Columns */}
                    <Box
                        sx={{
                            flex: 1,
                            pl: 2,
                            maxHeight: "80vh",
                            overflowY: "auto",
                        }}
                    >
                        <Typography variant="h6" gutterBottom>
                            Schema Columns
                        </Typography>
                        {schemaColumns.length > 0 ? (
                            <List>
                                {schemaColumns.map((column, index) => (
                                    <ListItem key={index}>
                                        <ListItemText
                                            primary={column.fieldname}
                                            secondary={column.dataType}
                                        />
                                        <IconButton
                                            onClick={() => moveColumnUp(index)}
                                            disabled={index === 0}
                                        >
                                            <ArrowUpwardIcon />
                                        </IconButton>
                                        <IconButton
                                            onClick={() => moveColumnDown(index)}
                                            disabled={index === schemaColumns.length - 1}
                                        >
                                            <ArrowDownwardIcon />
                                        </IconButton>
                                    </ListItem>
                                ))}
                            </List>
                        ) : (
                            <Typography>
                                Select a file to view its schema columns.
                            </Typography>
                        )}
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleAddDataReportItem}
                            disabled={!selectedFile || schemaColumns.length === 0}
                        >
                            Add Data
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </Box>
    );
};

export default ReportWriterTab;
