import React, { useEffect } from "react";
import { v4 as uuidv4 } from 'uuid';
import * as _ from 'lodash';
import { CanvasWidget } from "@projectstorm/react-canvas-core";
import { NodesTypesContainer } from "../nodes-types-container/NodesTypesContainer";
import { NodeTypeLabel } from "../node-type-label/NodeTypeLabel";
import { DiagramCanvas } from "../DiagramCanvas";
import { BusinessRuleModel } from "../businessRuleNode/BusinessRule";
import { FunctionComponent, FunctionModel } from "../functionNode/Function";
import "./creator-widget.css";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Stack, Button, useTheme, Autocomplete} from '@mui/material'
import Header from "../../Header";

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import { OutputComponent, OutputModel } from "../outputNode/Output";


import InputIcon from '@mui/icons-material/Input';
import DownloadIcon from '@mui/icons-material/Download';
import ClearIcon from '@mui/icons-material/Clear';
import FunctionsIcon from '@mui/icons-material/Functions';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import TableViewIcon from '@mui/icons-material/TableView';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';


import { FilterComponent, FilterModel } from "../filterNode/Filter";
import FileSchemaDataService from "../../../service/FileSchemaService";


import AddIcon from '@mui/icons-material/Add';
import JoinFullIcon from '@mui/icons-material/JoinFull';

import { JoinComponent, JoinDataModel } from "../joinDataNode/JoinData";
import { GroupByComponent, GroupByModel } from "../groupByNode/GroupBy";
import { SelectComponent, SelectModel } from "../selectNode/Select";

import { AppendRowComponent, AppendRowModel } from "../appendRowNode/AppendRow";
import { InputComponent, InputModel } from "../inputNode/Input";
import { AlterationComponent, AlterationModel } from "../alterationNode/Alteration";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';



import Grid from '@mui/material/Grid';
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
.creator-content{
    background-color:#ebe8e8;

    .creator-content-left{
     background-color:white;

     .Mui-expanded{
       margin:0;
     }
    }

    .creator-layer{
        padding:10px;
    }
}
`;

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
            style={{ width: '100%' }}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}


export const CreatorWidget = props => {

    
    const [count, setCount] = React.useState(0);


    const forceUpdate = React.useReducer(bool => !bool)[1];

    const [nodeIsOpen, setNodeIsOpen] = React.useState(false);

    const diagramEngine = props.engine;
    const processFlowReadOnly = props.processFlowReadOnly;
    const currentAudit = props.currentAudit;
    const currentProcessflowstagekey = props.currentProcessflowstagekey;
    const currentConversionstatuskey = props.currentConversionstatuskey;
    const selectedNetworkByAudit = props.selectedNetworkByAudit;
    

    //console.log(222, diagramEngine, currentAudit, props.processFlowNameChanger);
   // const currentComponent = props.currentComponent;


    const handleDataSetClose = () => {
        setNodeIsOpen(false);
    }

    const save = () => {
        //console.log(props.currentComponent)
        if (props.currentComponent?.options.type === 'Input' && (!props.currentComponent.nodedata || !props.currentComponent.nodedata.FileName)) {
            alert("Please select a file");
            return;
        }
        else if (props.currentComponent?.options.type === 'GroupBy') {
            diagramEngine.getModel().getNodes().forEach(node => {
                if (node.options.type == 'Output' && node.nodedata.OutPutDataLocation) {
                    const parentsNodes = Object.values(node.ports['in'].links).map(link => link.sourcePort.parent);
                    if (parentsNodes) {
                        parentsNodes.forEach(parent => {
                            if (parent.outputFields) {
                                node.inputFields = parent.outputFields
                                node.outputFields = parent.outputFields
                            }
                        })
                    }
                }
            })
        }

        setNodeIsOpen(false);
    }



    // force update canvas
    const onNodeDrop = event => {

        if (processFlowReadOnly) {
            alert("Process flow has been locked, please do not edit it.");
            return;
        }

        var data = JSON.parse(event.dataTransfer.getData('storm-diagram-node'));
        var node_id = uuidv4().replaceAll('-', '_');

        setCount(count + 1);

        var node = null;

        if (data.type === 'BusinessRule') {
            node = new BusinessRuleModel({ id: node_id, name: "BusinessRule", color: "#a4a9fc" });
        } else if (data.type === 'Function') {
            node = new FunctionModel({ id: node_id, name: "Function", color: "#6a6b6b" });
        } else if (data.type === 'input') {
            node = new InputModel({ id: node_id, inputfile: "" });
        } else if (data.type === 'output') {
            node = new OutputModel({id: node_id});
        } else if (data.type === 'Filter') {
            node = new FilterModel({ id: node_id });
        } else if (data.type === 'JoinData') {
            node = new JoinDataModel({ id: node_id, name: "JoinData" });
        } else if (data.type === 'GroupBy') {
            node = new GroupByModel({ id: node_id, name: "GroupBy" });
        } else if (data.type === 'Select') {
            node = new SelectModel({ id: node_id, name: "Select" });
        } else if (data.type === 'AppendRow') {
            node = new AppendRowModel({ id: node_id, name: "AppendRow" });
        } else if (data.type === 'Alteration') {
            node = new AlterationModel({ id: node_id, name: "Alteration" });
        } 

        const point = diagramEngine.getRelativeMousePoint(event);
        node.setPosition(point);
        diagramEngine.getModel().addNode(node);
        forceUpdate();
    };

    return (
        <Stack>
            <GlobalStyle />
            <div className="creator-body">
                <div className="creator-content">
                    {/*<Tabs*/}
                    {/*    orientation="vertical"*/}
                    {/*    variant="scrollable"*/}
                    {/*    value={stepValue}*/}
                    {/*    onChange={handleChange}*/}
                    {/*    aria-label="Vertical tabs example"*/}
                    {/*    sx={{*/}
                    {/*        borderRight: 1, borderColor: 'divider', width: '260px', boxShadow: '4px -1px 11px 3px rgb(126 123 123 / 14%)',*/}
                    {/*        '& .Mui-selected': {*/}
                    {/*            backgroundColor: '#deeaf9'*/}
                    {/*        }*/}
                    {/*    }}*/}
                    {/*>*/}
                    {/*    <Tab icon={<StorageIcon />} sx={{*/}
                    {/*        display: 'flex',*/}
                    {/*        flexDirection: 'row',*/}
                    {/*        justifyContent: 'flex-start',*/}
                    {/*        '& > svg': { marginRight: '20px', marginBottom: '0px !important' }*/}
                    {/*    }}*/}
                    {/*        label="Files Upload" {...a11yProps(0)} />*/}
                    {/*    <Tab label="Process Flow" {...a11yProps(1)} />*/}
                    {/*    <Tab label="Business Rules Manager" {...a11yProps(2)} />*/}
                    {/*</Tabs>*/}
                    {/*<TabPanel value={stepValue} index={0}>*/}
                    {/*   123*/}
                    {/*</TabPanel>*/}
                    {/*<TabPanel value={stepValue} index={1}>*/}
                    {/*    Item Two*/}
                    {/*</TabPanel>*/}
                    {/*<TabPanel value={stepValue} index={2}>*/}
                    {/*    Item Three*/}
                    {/*</TabPanel>*/}
                    <Stack className="creator-content-left" direction="row" >
                        <Box sx={{ width: 150 }}>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography >Datasets</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                    <NodesTypesContainer>
                                                <NodeTypeLabel model={{ type: "input" }} name="Input DataSet" icon={<img
                                                    alt="Plus"
                                                    width="30px"
                                                    height="30px"
                                                    src={"../../assets/cloud_download.svg"}
                                                />} />
                                                <NodeTypeLabel model={{ type: "output" }} name="Output DataSet" icon={
                                                    <img
                                                        alt="Plus"
                                                        width="30px"
                                                        height="30px"
                                                        src={"../../assets/cloud_upload.svg"}
                                                    />} />
                                </NodesTypesContainer>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography >Functions</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                    <NodesTypesContainer>
                                                <NodeTypeLabel model={{ type: "Function" }} name="Function" icon={<FunctionsIcon sx={{ fontSize: 30 }} />} />
                                                <NodeTypeLabel model={{ type: "Select" }} name="Select" icon={<TableViewIcon sx={{ fontSize: 30 }} />} />
                                </NodesTypesContainer>
                            </AccordionDetails>
                            </Accordion>

                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography >Filters</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <NodesTypesContainer>
                                           <NodeTypeLabel model={{ type: "Filter" }} name="filter" icon={<FilterAltOutlinedIcon sx={{ fontSize: 30 }} />} />
                                    </NodesTypesContainer>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography >Combine Data</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <NodesTypesContainer>
                                          <NodeTypeLabel model={{ type: "JoinData" }} name="Join Data" icon={<JoinFullIcon sx={{ fontSize: 30 }} />} />
                                    </NodesTypesContainer>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography >Aggregate</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <NodesTypesContainer>
                                                <NodeTypeLabel model={{ type: "GroupBy" }} name="Group By" icon={<CloseFullscreenIcon sx={{ fontSize: 30 }} />} />  
                                                <NodeTypeLabel model={{ type: "AppendRow" }} name="Append Rows" icon={<ViewColumnIcon sx={{ fontSize: 30 }} />} />
                                                <NodeTypeLabel model={{ type: "Alteration" }} name="Alteration" icon={<DriveFileRenameOutlineIcon sx={{ fontSize: 30 }} />} />
                                    </NodesTypesContainer>
                                </AccordionDetails>
                        </Accordion>
                        </Box>
                    </Stack>
                    <div
                        id="canvas"
                        className="creator-layer"
                        onDrop={event => onNodeDrop(event)}

                        onDoubleClick={(event) => setNodeIsOpen(true)}

                        onMouseLeave={event => {
                            if (props.currentComponent) {
                                props.currentComponent.options.selected = false;
                            }
                        }}
                        onDragOver={event => {
                            event.preventDefault();
                        }}
                        onMouseUp={() => {
                            const model = diagramEngine.getModel();
                            const listLink = Object.values(model.getLinks());
                            listLink.forEach(link => {
                                if (!link.getTargetPort()) {
                                    model.removeLink(link);
                                }
                            });
                        }}
                    >
                        <DiagramCanvas background='#F0F4F8'>
                            <CanvasWidget engine={diagramEngine} allowLooseLinks={false} />
                        </DiagramCanvas>
                    </div>
                </div>

                <br />


   

                <Stack>

                    <Dialog
                        modal={true}
                        maxWidth='xl'
                        width="100%"
                        open={nodeIsOpen}
                        onClose={handleDataSetClose}
                        aria-labelledby="example-modal-sizes-title-lg"
                        PaperProps={{
                            sx: {
                                width: "70%",
                                minHeight: '50%'
                            }
                        }}>
                        <DialogTitle id="alert-dialog-title">
                            <Header title={props.currentComponent?.options.name} handleModalClose={handleDataSetClose} />
                        </DialogTitle>
                        <DialogContent>


                            {props.currentComponent?.options.type === 'Input' && <InputComponent currentAudit={currentAudit} selectedNetworkByAudit={selectedNetworkByAudit} currentProcessflowstagekey={currentProcessflowstagekey} currentComponent={props.currentComponent} ></InputComponent>}
                    {props.currentComponent?.options.type === 'Output' && <OutputComponent currentComponent={props.currentComponent} model={diagramEngine.getModel()}> </OutputComponent>}
                    {props.currentComponent?.options.type === 'BusinessRule' && <Box spacing={5} width={"50%"}>
                        <Header title="Business Rule" />
                        <FormControl fullWidth>
                            <TextField id="Business-Rule-Name-label" label="Business Rule Name" variant="outlined" />
                            <br />
                            <TextField id="Business-Rule Detail-label" label="Business Rule Detail" variant="outlined" />
                        </FormControl>
                    </Box>
                    }

                    {props.currentComponent?.options.type === 'Function' && <FunctionComponent currentComponent={props.currentComponent}></FunctionComponent>
                    }

                    <br />
                    {props.currentComponent?.options.type === 'Filter' && <FilterComponent currentComponent={props.currentComponent}></FilterComponent>
                    }

                    {props.currentComponent?.options.type === 'JoinData' && <JoinComponent currentComponent={props.currentComponent} engine={diagramEngine}></JoinComponent>}

                    {props.currentComponent?.options.type === 'GroupBy' && <GroupByComponent currentComponent={props.currentComponent} engine={diagramEngine}></GroupByComponent>}
                            {props.currentComponent?.options.type === 'Select' && <SelectComponent currentComponent={props.currentComponent} currentConversionstatuskey={currentConversionstatuskey}></SelectComponent>}
                    {props.currentComponent?.options.type === 'AppendRow' && <AppendRowComponent currentComponent={props.currentComponent} ></AppendRowComponent>}
                    {props.currentComponent?.options.type === 'Alteration' && <AlterationComponent currentComponent={props.currentComponent} ></AlterationComponent>}
                     
                    </DialogContent>
                        <DialogActions>

                            <Button color="secondary" variant="contained" disabled={processFlowReadOnly} onClick={save}>
                                Save
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Stack>

        </div>
        </Stack>
 
    );
};
