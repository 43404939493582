import React, { useState, useEffect } from "react";
import {
    Box,
    Button,
    Stack,
    Card,
    IconButton,
    ListItemIcon,
    Tooltip,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import DragIndicatorOutlinedIcon from "@mui/icons-material/DragIndicatorOutlined";
import DoneIcon from "@mui/icons-material/Done";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import QuillEditor from "../../components/common/QuillEditor";
import parse from "html-react-parser";
import "./ReportSection.css";
import auditReportService from "../../service/AuditReportService";

const ReportSection = ({
    client,
    network,
    reportSectionList,
    setReportSectionList,
    currentTab,
    setCurrentTab,
    handleDeleteNote,
    isEditActive,
    handleDoneEdit,
    editorContent,
    handleContentChange,
    handleChangeToEdit,
    auditReportKey,
    setAuditReportKey,
    currentAudit,
    auditConfigurationId,
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        console.log('ReportSection props:', { reportSectionList, currentTab, auditReportKey });
    }, [reportSectionList, currentTab, auditReportKey]);

    const moveItemUp = (event, index) => {
        event.stopPropagation();
        if (index === 0) return; // Can't move the first item up

        const updatedReportSectionList = [...reportSectionList];
        const currentReportList = [...updatedReportSectionList[currentTab].reportList];

        [currentReportList[index - 1], currentReportList[index]] = [
            currentReportList[index],
            currentReportList[index - 1],
        ];

        // Update itemOrder based on new index
        currentReportList.forEach((item, idx) => {
            item.itemOrder = idx;
        });

        updatedReportSectionList[currentTab].reportList = currentReportList;
        setReportSectionList(updatedReportSectionList);
    };

    const moveItemDown = (event, index) => {
        event.stopPropagation();
        const reportListLength = reportSectionList[currentTab].reportList.length;
        if (index === reportListLength - 1) return; // Can't move the last item down

        const updatedReportSectionList = [...reportSectionList];
        const currentReportList = [...updatedReportSectionList[currentTab].reportList];

        [currentReportList[index], currentReportList[index + 1]] = [
            currentReportList[index + 1],
            currentReportList[index],
        ];

        // Update itemOrder based on new index
        currentReportList.forEach((item, idx) => {
            item.itemOrder = idx;
        });

        updatedReportSectionList[currentTab].reportList = currentReportList;
        setReportSectionList(updatedReportSectionList);
    };

    const handleSave = async () => {
        setIsLoading(true);
        setError(null);

        try {
            const requestData = {
                auditReportKey: auditReportKey || 0, // Use 0 or null for new reports
                auditKey: currentAudit.audit.auditkey,
                auditConfigurationKey: auditConfigurationId,
                rptDesc: `Audit Report for ${client.clientName} - ${network.networkName}`,
                rptName: `${client.clientName} - ${network.networkName} Audit Report`,
                rptLocation: "",
                sections: reportSectionList.map(section => ({
                    auditReportSectionKey: section.auditreportsectionkey || 0,
                    auditReportSectionDescKey: section.auditreportsectiondesckey,
                    sectionTitle: section.sectiontitle,
                    reportItems: section.reportList.map(item => ({
                        content: item.value,
                        type: item.type,
                        auditReportItemDescKey: item.auditreportitemdesckey,
                        itemOrder: item.itemOrder,
                        rptColumnOrder: item.RptColumnOrder || null,
                        fileConfigKey: item.fileConfigKey || null,
                        contractTermsKey: item.contractTermsKey || null,
                    })),
                })),
            };

            const saveResponse = await auditReportService.saveAuditReportWithSections(requestData);

            // Update local state with new keys
            setAuditReportKey(saveResponse.data.dto.auditReportKey);
            setReportSectionList(
                saveResponse.data.dto.sections.map(section => ({
                    ...section,
                    auditreportsectionkey: section.auditReportSectionKey,
                    sectiontitle: section.sectionTitle,
                    reportList: section.reportItems.map(item => ({
                        ...item,
                        value: item.content,
                        type: item.type,
                        auditreportitemdesckey: item.auditReportItemDescKey,
                        itemOrder: item.itemOrder,
                        RptColumnOrder: item.rptColumnOrder,
                        fileConfigKey: item.fileConfigKey,
                    })),
                }))
            );

            console.log('Audit report saved successfully');
        } catch (err) {
            console.error('Error saving audit report:', err.response ? err.response.data : err);
            setError(`Failed to save the audit report: ${err.response ? err.response.data.error : err.message}`);
        } finally {
            setIsLoading(false);
        }
    };

    if (!reportSectionList || reportSectionList.length === 0) {
        return (
            <Box className="report-writer-tab__report-section">
                <div className="no-rows">
                    <p>Report Section Data Not Available</p>
                </div>
            </Box>
        );
    }

    const currentSection = reportSectionList[currentTab];

    if (!currentSection) {
        console.error('Current section is undefined. CurrentTab:', currentTab, 'ReportSectionList:', reportSectionList);
        return (
            <Box className="report-writer-tab__report-section">
                <div className="no-rows">
                    <p>Error: Unable to load current section</p>
                </div>
            </Box>
        );
    }

    const isAnyEditActive = currentSection.reportList.some(item => item.isEdit);

    return (
        <Box className="report-writer-tab__report-section">
            <div className="report-section__header">
                <p>Report Section</p>
                <Button
                    color="primary"
                    variant="outlined"
                    sx={{ height: "36px" }}
                    disabled={!(client && network) || isEditActive || isLoading}
                    onClick={handleSave}
                >
                    {isLoading ? 'Saving...' : 'Save'}
                </Button>
            </div>

            {error && (
                <div className="error-message" style={{ color: 'red', marginBottom: '10px' }}>
                    {error}
                </div>
            )}

            <div className="report-section_navigation">
                <p>{currentSection.sectiontitle || 'No section title available'}</p>

                <Stack gap={3} direction="row" className="mt-3">
                    <Button
                        color="primary"
                        variant="contained"
                        disabled={currentTab === 0 || reportSectionList.length === 1 || isEditActive}
                        onClick={() => setCurrentTab((prev) => prev - 1)}
                    >
                        <NavigateBeforeIcon />
                        Previous Section
                    </Button>

                    <Button
                        color="primary"
                        variant="contained"
                        disabled={currentTab === reportSectionList.length - 1 || reportSectionList.length === 1 || isEditActive}
                        onClick={() => setCurrentTab((prev) => prev + 1)}
                    >
                        Next Section
                        <NavigateNextIcon />
                    </Button>
                </Stack>
            </div>

            <Box className="report-section__writing">
                {currentSection.reportList.length > 0 ? (
                    currentSection.reportList.map((item, index) => (
                        <Card
                            key={index}
                            className={`card-list ${isAnyEditActive && !item.isEdit
                                    ? "edit-text-disabled"
                                    : "pointer-cursor"
                                }`}
                            sx={{ marginBottom: "16px", overflow: "visible" }}
                            onClick={(e) => handleChangeToEdit(e, item.value, index)}
                        >
                            <div
                                style={{
                                    padding: "16px 0 16px 16px",
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                {/* Up and Down Arrow Buttons */}
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        marginRight: "8px",
                                    }}
                                >
                                    <Tooltip title="Move Up">
                                        <IconButton
                                            color="primary"
                                            onClick={(e) => moveItemUp(e, index)}
                                            disabled={index === 0 || isEditActive}
                                        >
                                            <ArrowUpwardIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Move Down">
                                        <IconButton
                                            color="primary"
                                            onClick={(e) => moveItemDown(e, index)}
                                            disabled={
                                                index === currentSection.reportList.length - 1 ||
                                                isEditActive
                                            }
                                        >
                                            <ArrowDownwardIcon />
                                        </IconButton>
                                    </Tooltip>
                                </div>

                                {/* Existing Content */}
                                <ListItemIcon
                                    className="drag-handle"
                                    sx={{
                                        minWidth: "25px",
                                        cursor: "grabbing",
                                        marginRight: "5px",
                                    }}
                                >
                                    <DragIndicatorOutlinedIcon />
                                </ListItemIcon>

                                {item.isEdit ? (
                                    <>
                                        {/* Edit Mode Content */}
                                        <div style={{ width: "100%" }}>
                                            <QuillEditor
                                                width={"100%"}
                                                initialValue={editorContent}
                                                onContentChange={handleContentChange}
                                            />
                                        </div>
                                        <div
                                            style={{ display: "flex", flexDirection: "column" }}
                                        >
                                            <Tooltip title="Save">
                                                <IconButton
                                                    color="primary"
                                                    onClick={handleDoneEdit}
                                                    disabled={
                                                        editorContent === "" ||
                                                        editorContent.replaceAll(" ", "") ===
                                                        "<p><br></p>"
                                                    }
                                                >
                                                    <DoneIcon />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Delete">
                                                <IconButton
                                                    color="primary"
                                                    onClick={(e) => handleDeleteNote(e, index)}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                    </>
                                ) : (
                                    /* Display Mode Content */
                                    <div className="display-parse-text">
                                        <div
                                            id={`quill-text-${index}`}
                                            className="quill-text"
                                        >
                                            {parse(item.value)}
                                        </div>
                                        {!isAnyEditActive && (
                                            <div className="delete-btn">
                                                <Tooltip title="Delete">
                                                    <IconButton
                                                        color="primary"
                                                        onClick={(e) => handleDeleteNote(e, index)}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        </Card>
                    ))
                ) : (
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "100%",
                        }}
                    >
                        No Data
                    </div>
                )}
            </Box>
        </Box>
    );
};

export default ReportSection;
