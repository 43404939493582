import { jsonHttp } from "./http-common";

export class ProcessFlowDataService {
    getAll() {
        return jsonHttp.get("/ProcessFlow");
    }

    get(id) {
        return jsonHttp.get(`/ProcessFlow/${id}`);
    }


    getProcessFlowByClient(clientKey ) {
        return jsonHttp.get(`/ProcessFlow/client/${clientKey}`);
    }

    getProcessFlowByAffiliate(affiliateKey) {
        return jsonHttp.get(`/ProcessFlow/affiliate/${affiliateKey}`);
    }

    getProcessFlowByAuditKey(auditKey) {
        return jsonHttp.get(`/ProcessFlow/audit/${auditKey}`);
    }

    getImportprocessflows(auditKey) {
        return jsonHttp.get(`/ProcessFlow/getImportprocessflows/${auditKey}`);
    }
    getImportprocessflowDetail(processflowkey) {
        return jsonHttp.get(`/ProcessFlow/getImportprocessflowDetail/${processflowkey}`);
    }
    getImportprocessflowsByAffiliate(auditKey, affiliatekey, stagekey) {
        return jsonHttp.get(`/ProcessFlow/getImportprocessflowsByAffiliatekey/${auditKey}/${affiliatekey}/${stagekey}`);
    }
    getImportprocessflowsByClient(auditKey, clientkey, stagekey) {
        return jsonHttp.get(`/ProcessFlow/getImportprocessflowsByClientkey/${auditKey}/${clientkey}/${stagekey}`);
    }
    
    create(data) {
        return jsonHttp.post("/ProcessFlow", data).catch(error => {
            let e = error;
            if (error.response) {
                if (error.response.data) {
                    e = error.response.data;           
                }
            } else if (error.message) {
                e = error.message;
            } else {
                e = "Unknown error occured";
            }
            throw new Error(e);
        })
    }

    update(id, data) {
        return jsonHttp.put(`/ProcessFlow/${id}`, data);
    }

    delete(id) {
        return jsonHttp.delete(`/ProcessFlow/${id}`);
    }

}
export default new ProcessFlowDataService();