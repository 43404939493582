import { jsonHttp } from "./http-common";

class FileConfigDataService {
    getAll() {
        return jsonHttp.get("/FileConfig");
    }

    get(id) {
        return jsonHttp.get(`/FileConfig/${id}`);
    }

    getFilesByClient(id) {
        return jsonHttp.get(`/FileConfig/client/${id}`);
    }


    getFilesByClientAndStatusKey(id, key) {
        return jsonHttp.get(`/FileConfig/clientAndStatusKey/${id}/${key}`);
    }

    getFilesByAffiliateOrClientAndStatusKey(affiliateKey, auditkey, processflowstagekey, clientkey, networkkey) {
        return jsonHttp.get(`/FileConfig/affiliateOrClientAndStatusKey/${affiliateKey}/${auditkey}/${processflowstagekey}/${clientkey}/${networkkey}`);
    }

    getFilesByAffiliateOrClientAndFileType(auditKey, processflowstagekey, affiliateKey, clientkey, networkkey, fileType) {
        return jsonHttp.get(`/FileConfig/affiliateOrClientAndFileType/${auditKey}/${processflowstagekey}/${affiliateKey}/${clientkey}/${networkkey}/${fileType}`);
    }

    getApproveFileType(systemclientkey,filetypestagekey) {
        return jsonHttp.get(`/FileConfig/getApproveFileType/${systemclientkey}/${filetypestagekey}`);
    }

    getFilesByClientAndFileType(id, type) {
        return jsonHttp.get(`/FileConfig/clientAndFileType/${id}/${type}`);
    }

    getFilesByClientOrAffiliate(id) {
        return jsonHttp.get(`/FileConfig/clientOrAffiliate/${id}`);
    }
    
    getFilesByStatusKey(key) {
        return jsonHttp.get(`/FileConfig/StatusKey/${key}`);
    }

    getFileConfigByProcessFlowKey(key) {
        return jsonHttp.get(`/FileConfig/GetFileConfigByProcessFlowKey/${key}`);
    }

    getProcessflowDataGrid(auditkey) {
        return jsonHttp.get(`/FileConfig/GetProcessflowDataGrid/${auditkey}`);
    }

    getMappingProcessflowItems(auditmodelkey) {
        return jsonHttp.get(`/FileConfig/GetMappingProcessflowItems/${auditmodelkey}`);
    }

    create(data) {
        return jsonHttp.post("/FileConfig", data);
    }

    update(id, data) {
        return jsonHttp.put(`/FileConfig/${id}`, data);
    }

    updateClientFileSchemaKey(data) {
        return jsonHttp.put(`/FileConfig/updateClientFileSchemaKey`, data);
    }

    updateClientFileSchemaKeyWithoutStartingPipeline(data) {
        return jsonHttp.put(`/FileConfig/updateClientFileSchemaKeyWithoutStartingPipeline`, data);
    }

    approve(data) {
        return jsonHttp.put(`/FileConfig/Approve`, data);
    }

    revert(data) {
        return jsonHttp.put(`/FileConfig/revert`, data);
    }

    executeProcessFlow(data) {
        return jsonHttp.put(`/FileConfig/executeProcessFlow`, data);
    }

    processAll(data) {
        return jsonHttp.put(`/FileConfig/processAll`, data);
    }

    getApproveDetail(auditkey,id) {
        return jsonHttp.get(`/FileConfig/getApproveDetail/${auditkey}/${id}`);
    }

    getFinalDataFilesWithClients(auditkey,clientkey,networkkey) {
        return jsonHttp.get(`/FileConfig/getFinalDataFilesWithClients/${auditkey}/${clientkey}/${networkkey}`);
    }

    async getFileconfigkeyByOutputid(id) {
        return await jsonHttp.get(`/FileConfig/getFileconfigkeyByOutputid/${id}`);
    }

    delete(id) {
        return jsonHttp.delete(`/FileConfig/${id}`);
    }

}
export default new FileConfigDataService();