import { NodeModel} from "@projectstorm/react-diagrams";
import { AdvancedPortModel } from "../link/link";
import React from "react";
import { PortWidget } from "@projectstorm/react-diagrams-core";
import { AbstractReactFactory } from "@projectstorm/react-canvas-core";
import { DefaultPortModel } from '@projectstorm/react-diagrams';


import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Box, Stack } from '@mui/material'
import Header from "../../Header";
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import FunctionsIcon from '@mui/icons-material/Functions';


export class FunctionModel extends NodeModel {
    constructor(options) {
        super({
            ...options,
            name: options?.name || "Function",
            id: options?.id,
            type: "Function"
        });

        if (options) {
            this.color = options.color || "black";
        }

        this.depth = 0;
        this.inputFields = new Array();
        this.outputFields = new Array();
        this.precendentNodes = new Set();
        this.nodedata = options?.nodedata;

        // setup an in and out port
        this.addPort(
            new DefaultPortModel({
                in: true,
                name: "in"
            })
        );
        this.addPort(
            new DefaultPortModel({
                in: false,
                name: "out"
            })
        );
    }


    serialize() {
        return {
            ...super.serialize(),
            depth: this.depth,
            inputFields : this.inputFields,
            outputFields: this.outputFields,
            name: this.options.name,
            nodedata: this.nodedata,
            precendentNodes: Array.from(this.precendentNodes),
        }
    }

    deserialize(event, engine) {
        super.deserialize(event, engine);
        this.depth = event.data.depth;
        this.options.name = event.data.name;
        this.inputFields = event.data.inputFields;
        this.outputFields = event.data.outputFields;
        this.nodedata = event.data.nodedata;
        this.precendentNodes = new Set(event.data.precendentNodes);
    }

}

export const FunctionNodeWidget = props => {
    return (
        <div className="node">
            {/*<div*/}
            {/*    className="node-header-container"*/}
            {/*    style={{ backgroundColor: props.node.color }}*/}
            {/*>*/}
            {/*    <div className="my-icon" />*/}
            {/*    <div className="node-header-text">Function</div>*/}
            {/*</div>*/}

            <PortWidget
                className="port-container left-port"
                engine={props.engine}
                port={props.node.getPort("in")}
            >
                <div className="node-port" />
            </PortWidget>

            {/*<div className="node-content">Function Description</div>*/}


            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexShrink: '0',
                boxShadow: '0 .5rem 1rem rgba(0,0,0,.15)!important',
                padding: '5px',
                backgroundColor: 'white',
                border: '5px solid #a4a9fc'
            }}>
                <FunctionsIcon
                    width="65px"
                    height="65px"
                    sx={{ width: "65px", height: '65px' }}
                />
            </Box>
            <Box className="label-name">{props.node.options.name}</Box>

            <PortWidget
                className="port-container right-port"
                engine={props.engine}
                port={props.node.getPort("out")}
            >
                <div className="node-port" />
            </PortWidget>
        </div>
    );
};

// TODO: Refactor to hooks
export class FunctionNodeFactory extends AbstractReactFactory {
    constructor() {
        super("Function");
    }

    generateModel(initialConfig) {
        return new FunctionModel();
    }

    generateReactWidget(event) {
        return <FunctionNodeWidget engine={this.engine} node={event.model} />;
    }
}

export function FunctionComponent(props) {
    const operators = ["APPEND", "ADD", "MINUS"];

    const currentComponent = props.currentComponent;

    const [functions, setFunctions] = React.useState([]);
    const [nodeName, setNodeName] = React.useState('');

    React.useEffect(() => {

        if (currentComponent.options && currentComponent.options.name) {
            setNodeName(currentComponent.options.name)
        }
        if (currentComponent.nodedata && currentComponent.nodedata.operations) {
            const operations = currentComponent.nodedata.operations;
            setFunctions(operations);
         
        }
    }, []);



    const handleAddFunctions = (event) => {
        const newFunctions = Array.from(functions);
        newFunctions.push({ fieldName: '', functionDetail: '' });
        setFunctions(newFunctions)
    };


    const handleDeleteFunctions = (event, id) => {
        let fieldNameDeleted = functions.filter((func, index) => index === id)[0].fieldName;
        const newFuncs = functions.filter((func, index) => index !== id)
        setFunctions(newFuncs);
        if (currentComponent.nodedata) {
            currentComponent.nodedata.operations = newFuncs
        } else {
            currentComponent.nodedata = { operations: newFuncs }
        }
        currentComponent.outputFields = currentComponent.outputFields.filter((func) => func.FieldName !== fieldNameDeleted);
    };


    return (<Box spacing={5} width={"100%"}>
            <br />
        <FormControl style={{ minWidth: 300, maxWidth: 300 }}>
            <TextField
                autoComplete='off'
                label="Node Name"
                value={nodeName}
                onChange={(event) => {
                    setNodeName(event.target.value)
                    currentComponent.options.name = event.target.value;
                }}
            />
        </FormControl>
    
        <Box>
            <Button color="primary" variant="contained" startIcon={<AddIcon />} onClick={handleAddFunctions}>
                Add Function
            </Button>
        </Box>

        <br />

        {functions.map((func, index) => (
            <Stack direction="row" spacing={2} width={"50%"}>
                <FormControl style={{ minWidth: 300, maxWidth: 300 }}>
                    <TextField
                        autoComplete='off'
                        label="New Column Name"
                        value={func.fieldName}
                        onChange={(event) => {
                            const newFunctions = [...functions];
                            const newFunc = newFunctions[index]
                            newFunc.fieldName = event.target.value
                            setFunctions(newFunctions)

                            if (currentComponent.nodedata) {
                                currentComponent.nodedata.operations = newFunctions
                            } else {
                                currentComponent.nodedata = { operations: newFunctions }
                            }

                            const parentsNodes = Object.values(currentComponent.ports['in'].links).map(link => link.sourcePort.parent);
                            if (parentsNodes) {
                                parentsNodes.forEach(parent => {
                                    if (parent.outputFields) {
                                        currentComponent.inputFields = parent.outputFields
                                    }
                                })
                            }

                            const outputFields = Array.from(currentComponent.inputFields);
                            console.log(outputFields)
                            currentComponent.nodedata.operations.forEach(operation => outputFields.push({ id: outputFields.length+1, FieldName: operation.fieldName }));
                            currentComponent.outputFields = outputFields;
                        }}
                    />
                </FormControl>

                <FormControl style={{ minWidth: 600, maxWidth: 600 }}>
                    <TextField
                        multiline
                        maxRows={4}
                        autoComplete='off'
                        label="Function Detail"
                        value={func.functionDetail}
                        onChange={(event) => {
                            const newFunctions = [...functions];
                            const newFunc = newFunctions[index]
                            newFunc.functionDetail = event.target.value
                            setFunctions(newFunctions)

                            if (currentComponent.nodedata) {
                                currentComponent.nodedata.operations = newFunctions
                            } else {
                                currentComponent.nodedata = { operations: newFunctions }
                            }

                            const parentsNodes = Object.values(currentComponent.ports['in'].links).map(link => link.sourcePort.parent);
                            if (parentsNodes) {
                                parentsNodes.forEach(parent => {
                                    if (parent.outputFields) {
                                        currentComponent.inputFields = parent.outputFields
                                    }
                                })
                            }

                            const outputFields = Array.from(currentComponent.inputFields);
                            currentComponent.nodedata.operations.forEach(operation => outputFields.push({ id: outputFields.length + 1, FieldName: operation.fieldName }));
                            currentComponent.outputFields = outputFields;
                        }}
                    />
                    <br />
                </FormControl>

                <IconButton aria-label="delete"
                    sx={{
                        minWidth: 20,
                        maxWidth: 20,
                    }} onClick={(event) => handleDeleteFunctions(event, index)}>
                    <ClearIcon />
                </IconButton>
             
            </Stack>

        ))
        }
    </Box>)
}
