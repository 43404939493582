import { ColorModeContext, useMode } from "./theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { Routes, Route, useNavigate  } from "react-router-dom";
import { MyProSidebarProvider } from "./pages/global/sidebar/sidebarContext";
import Topbar from "./pages/global/Topbar";
import Aduit from "./pages/createAudit";
import AduitHomeTab from "./pages/auditHomeTab";
import DataIngestion from "./pages/DataIngestion";
import ContractIngestion from "./pages/contractIngestion/contractIngestion";
import Login from './login/login';
import CreateSchema from "./pages/createSchema/index";
import AssignDateToFile from "./pages/assignDateToFile/index";
import DataOverview from "./pages/dataOverview/index";
import Wizard from "./pages/wizard/index";
import SchemaProcessed from "./pages/schemaProcessed/index";
import MyDiagram from "./pages/diagram/index";
import AuditReport from "./pages/auditReport/auditReport";
import ContractManagement from "./pages/contractManagement/contractManagement";
import {UserManagement } from "./pages/admin/UserManagement";
import {PermissionManagement} from "./pages/admin/PermissionManagement"
import ProcessFlow from "./pages/processFlow/index";
import { RoleComposite } from './ts/modules/roles/interface';
import { useAuthContext } from './providers/AuthProvider';
import { useEffect, useState } from "react";
import ConfigDataService from "./service/ConfigDataService"


import {LogLevel , EventType, PublicClientApplication , InteractionType, RedirectRequest} from "@azure/msal-browser"
import {MsalAuthenticationTemplate, useIsAuthenticated, useMsal,AuthenticatedTemplate, UnauthenticatedTemplate  } from "@azure/msal-react";

import { Logout } from "./pages/Logout";
import CompositePage from "./pages/compositePage/index";
import CreateAuditModel from "./pages/createAuditModel/index";
import FileTypeManagement from "./pages/fileTypeManagement/index";
import { AppRolePage} from "./pages/admin/AppRolePage"
//import { DataRolePage} from "./pages/admin/DataRolePage"

const App = ({ account, msalInstance} ) => {
    const [theme, colorMode] = useMode();

	//determine environment
	ConfigDataService.getConfigValue("ENV").then((res) => {
		console.log("App.js, env", res.data);
		localStorage.setItem("env", res.data);
	}).catch(e => {
		console.log(e)
	});

return (
	<ColorModeContext.Provider value={colorMode}>
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<MyProSidebarProvider >
				<div style={{ height: "100%", minHeight:"100vh", width: "100%" }}>
					<main>
						<Routes>
							<Route path="/audit" element={<ProtectedRoute account={account} ><Aduit /></ProtectedRoute>} />
							<Route path="/auditModel" element={<ProtectedRoute account={account} ><CreateAuditModel /></ProtectedRoute>} />
							<Route path="/compositepage" element={<ProtectedRoute account={account} ><CompositePage /></ProtectedRoute>} />
							<Route path="/compositepage/report-writer" element={<ProtectedRoute account={account} ><CompositePage /></ProtectedRoute>} />
							<Route path="/compositepage/audit-configuration" element={<ProtectedRoute account={account} ><CompositePage /></ProtectedRoute>} />
							<Route path="/compositepage/contract-configuration" element={<ProtectedRoute account={account} ><CompositePage /></ProtectedRoute>} />
							<Route path="/compositepage/fieldwork-process-flow" element={<ProtectedRoute account={account} ><CompositePage /></ProtectedRoute>} />
							<Route path="/compositepage/fieldwork-mapping" element={<ProtectedRoute account={account} ><CompositePage /></ProtectedRoute>} />
							<Route path="/compositepage/contract-term-process-flow" element={<ProtectedRoute account={account} ><CompositePage /></ProtectedRoute>} />
							<Route path="/compositepage/contract-mapping" element={<ProtectedRoute account={account} ><CompositePage /></ProtectedRoute>} />
							<Route path="/compositepage/discrepancy-analysis-process-flow" element={<ProtectedRoute account={account} ><CompositePage /></ProtectedRoute>} />
							<Route path="/compositepage/final-report-data-mapping" element={<ProtectedRoute account={account} ><CompositePage /></ProtectedRoute>} />
							<Route path="/compositepage/publish-report" element={<ProtectedRoute account={account} ><CompositePage /></ProtectedRoute>} />
							<Route path="/DataIngestion" element={ <ProtectedRoute  account={account}><DataIngestion /></ProtectedRoute>} />
							<Route path="/" element={<ProtectedRoute account={account} > <AduitHomeTab /> </ProtectedRoute>} />
							<Route path="/createSchema" element={<ProtectedRoute account={account} ><CreateSchema /></ProtectedRoute>} />
							<Route path="/assignDateToFile" element={<ProtectedRoute account={account} ><AssignDateToFile /></ProtectedRoute>} />
							<Route path="/dataOverview" element={<ProtectedRoute  account={account}><DataOverview /></ProtectedRoute>} />
							<Route path="/wizard" element={<ProtectedRoute  account={account}><Wizard /></ProtectedRoute> } />
							<Route path="/schemaProcessed" element={<ProtectedRoute  account={account}><SchemaProcessed /></ProtectedRoute> } />
							<Route path="/processflow" element={<ProtectedRoute  account={account}><MyDiagram /></ProtectedRoute> } />
							<Route path="/admin/roles/app" element={<ProtectedRoute /*permissions={['Admin']}*/  account={account}> <AppRolePage /></ProtectedRoute> } />
							<Route path="/admin/permission" element={<ProtectedRoute /*permissions={['Admin']}*/  account={account}><PermissionManagement /></ProtectedRoute> } />
							<Route path="/admin/usermanagement" element={<ProtectedRoute /*permissions={['Admin']}*/  account={account}><UserManagement /></ProtectedRoute> } />
							<Route path="/processflow" element={<ProtectedRoute account={account}><MyDiagram /></ProtectedRoute>} />
							<Route path="/fileTypeManagement" element={<ProtectedRoute account={account}><FileTypeManagement /></ProtectedRoute>} />
							{/*<Route path="/pivottable" element={<ProtectedRoute  account={account}><PivotTable /></ProtectedRoute> } />*/}
							<Route path="/auditReport" element={<ProtectedRoute account={account}><AuditReport /></ProtectedRoute>} />
							<Route path="/contractManagement" element={<ProtectedRoute account={account}><ContractManagement /></ProtectedRoute>} />
							<Route path="/contractIngestion" element={<ProtectedRoute account={account}><ContractIngestion /></ProtectedRoute>} />
							<Route path="/logout" element={<Logout />} />
							<Route path="/login" element={<Login />} />
						</Routes>
					</main>
				</div>
			</MyProSidebarProvider>
		</ThemeProvider>
	</ColorModeContext.Provider>
);
};





const ProtectedRoute = ({ children, permissions }: {children: ReactNode, permissions: Array<string>}) => {

	// const {  getUser,  login, isAuthenticated, } = useAuthContext();
	const { getUser, login, logout, setAuthToken } = useAuthContext();


	const { instance, accounts, inProgress } = useMsal();

	useEffect(() => {
		if ( accounts.length > 0 ){
		setAuthToken();
		}


	}, [inProgress])

	const account = accounts[0]

//	console.log("App > ProtectedRoute, userRoles", localStorage.getItem("userRoles"));
	const userRoles: RoleComposite[] = [];// JSON.parse(localStorage.getItem("userRoles"));

	const userPermissionSet = userRoles.map(role => role.permissions.map(permission => permission.rolePermissionDescription));

	//console.log("App > ProtectedRoute, permissions", permissions);
//	console.log("App > ProtectedRoute, userPermissionSet", userPermissionSet.flat(2));

	const canAccess = permissions ? userPermissionSet.flat(2).some(p => permissions.includes(p)) : true;

	//console.log("App > ProtectedRoute, canAccess", canAccess);

	const loginRequest: RedirectRequest = {
		scopes: ["openid", "profile", "offline_access","User.Read"],
		authenticationScheme: 'Bearer',         
	};


	const emailToByPassAuth = ['jthornberg@symphonymedia.com', 'mcannon@symphonymedia.com', 'ckasch@symphonymedia.com', 'jblais@symphonymedia.com', 'mdecorpo@symphonymedia.com', 'nberg@symphonymedia.com', 'afrey@symphonymedia.com', 'jmacdonald@symphonymedia.com', 'acarpenter@symphonymedia.com','gbryant@symphonymedia.com']

	let isAuthorizedToViewRoute = account?.idTokenClaims?.groups.includes('55584599-19e6-4017-9869-3e7a94a7bfb2')

	if(emailToByPassAuth.includes(account?.username) || canAccess){
		isAuthorizedToViewRoute = true  // TEMP SOLUTION UNTIL WE FIX ON-PREM VS CLOUD AD GROUP SYNCS
	}

	if((account && isAuthorizedToViewRoute === false) || !canAccess){
		return <div>Un-Authorized</div>
	}

	return <MsalAuthenticationTemplate
		interactionType={InteractionType.Redirect}
		authenticationRequest={loginRequest}
		errorComponent={(error) => <p>An Error Occurred: {error}</p>}
		loadingComponent={() =>  <div>Authentication in progress...</div>}>    
		{children}
	</MsalAuthenticationTemplate>

};



export default App;
