import React, { useState } from 'react';
import { Box, Grid, Typography, Button } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import FileConfigDataService from "../../service/FileConfigDataService";

// Mock data for the main table, allowing nullable values
//const mainTableRows = [
//    { id: 1, affiliate: 'Affiliate1', client: null, network: null, fileType: 'Conformed Billing' },
//    { id: 2, affiliate: null, client: 'Client2', network: 'Network2', fileType: 'Conformed Payment' },
//    { id: 3, affiliate: null, client: 'Client3', network: 'Network3', fileType: 'Conformed Payment' },
//    { id: 4, affiliate: null, client: 'Client4', network: 'Network4', fileType: 'Conformed Payment' },
//];

// Columns for the main table
const mainTableColumns = [
    { field: 'affiliatename', headerName: 'Affiliate' ,flex:1},
    { field: 'clientname', headerName: 'Client', flex: 1 },
    { field: 'networkname', headerName: 'Network', flex: 1 },
    { field: 'filetypename', headerName: 'File Type', flex: 1 },
];

// Mock data for the detail table based on different Affiliates/Clients
//const detailTableData = {
//    Affiliate1: [
//        { id: 1, processFlow: 'Flow A1', outputNode: 'Node A1' },
//        { id: 2, processFlow: 'Flow A2', outputNode: 'Node A2' },
//        { id: 3, processFlow: 'Flow A3', outputNode: 'Node A3' },
//    ],
//    Client2: [
//        { id: 1, processFlow: 'Flow B1', outputNode: 'Node B1' },
//        { id: 2, processFlow: 'Flow B2', outputNode: 'Node B2' },
//    ],
//    Client3: [
//        { id: 1, processFlow: 'Flow C1', outputNode: 'Node C1' },
//        { id: 2, processFlow: 'Flow C2', outputNode: 'Node C2' },
//    ],
//    Client4: [
//        { id: 1, processFlow: 'Flow D1', outputNode: 'Node D1' },
//        { id: 2, processFlow: 'Flow D2', outputNode: 'Node D2' },
//    ],
//};

// Columns for the detail table
const detailTableColumns = [
    { field: 'processflowname', headerName: 'Process Flow', width: 150 },
    { field: 'outputname', headerName: 'Output Node', width: 150 },
];

const ProcessFlowGrid = (prop) => {
    const { ProcessflowDataGrid, currentAudit, conversionstatuskey } = prop;
    const [selectedRow, setSelectedRow] = useState([null]);
    const [detailTableData, setDetailTableData] = useState([]);

    const handleRowClick = (params) => {
        //console.log(params.row)
        setSelectedRow(params.row);


        FileConfigDataService.getMappingProcessflowItems(params.row.auditModelKey).then((res) => {
            console.log(res);

            let items = res.data;
            if (items && items.length>0) {
                let _processflowitems = [];
                items.map((x, index) => {
                    let jsonddata = JSON.parse(x.processflows.processflowjsondata);
                    let outputname = findPropertyValue(jsonddata, "OutPutDataNm");
                    let fileConfigKey = findPropertyValue(jsonddata, "fileConfigKey");
                    let auditmodelassignmentkey = x.auditModelAssignments.auditmodelassignmentkey || -1;
                    _processflowitems.push({
                        id: index + 1, processflowkey: x.processflows.processflowkey, processflowname: x.processflows.processflownm, outputname: outputname, fileconfigKey: fileConfigKey, auditmodelassignmentkey: auditmodelassignmentkey
                    })
                });
                console.log(_processflowitems);
                setDetailTableData(_processflowitems);
            }
            
        }).catch(e => {
            console.log(e)
        });
    };

 

    const processAll = () => {
        let dto = ProcessflowDataGrid.map(x => {
            return { ...x, auditkey: currentAudit.audit.auditkey, conversionstatuskey: conversionstatuskey }
        });
        FileConfigDataService.processAll(dto).then((res) => {
            alert("Process all successful.");
        }).catch(err => console.log(err))
    }
    React.useEffect(() => {
        if (currentAudit && currentAudit.audit) {
            setDetailTableData([]);
        }
    }, [currentAudit])

    function findPropertyValue(obj, propName) {
        for (var key in obj) {
            if (obj.hasOwnProperty(key)) {
                if (key === propName && typeof obj[key] !== 'object') {
                    return obj[key];
                } else if (typeof obj[key] === 'object') {
                    var result = findPropertyValue(obj[key], propName);
                    if (result !== undefined) {
                        return result;
                    }
                }
            }
        }
        return undefined;
    }

    return (
        <Box m="20px">
            <Grid container justifyContent="space-between" alignItems="center">
                <Typography variant="h4" gutterBottom>
                    Approved Conformed File Types
                </Typography>
                <Button variant="contained" color="primary" onClick={() => { processAll()} }>
                    Process All
                </Button>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <div style={{ height: 400, width: '100%' }}>
                        <DataGrid
                            rows={ProcessflowDataGrid}
                            columns={mainTableColumns}
                            pageSize={5}
                            onRowClick={handleRowClick}
                        />
                    </div>
                </Grid>
                <Grid item xs={6}>
                    {selectedRow && (
                        <>
                            <Typography variant="h5" gutterBottom>

                                {selectedRow.affiliatename || selectedRow.clientname ? (selectedRow.affiliatename ?
                                    `Details for ${selectedRow.affiliatename}` :
                                    `Details for ${selectedRow.clientname} - ${selectedRow.networkname}`):""
                                }
                            </Typography>
                            <div style={{ height: 400, width: '100%' }}>
                                <DataGrid
                                    rows={detailTableData}
                                    columns={detailTableColumns}
                                    pageSize={5}
                                />
                            </div>
                        </>
                    )}
                </Grid>
            </Grid>
        </Box>
    );
};

export default ProcessFlowGrid;