import React, { useState } from 'react'
import { Box, Button, TextField, Stack, Divider, Autocomplete } from '@mui/material'
import { Formik, setFieldValue } from "formik";
import * as yup from 'yup';
import { useMediaQuery } from "@mui/material";
import Header from "../../components/Header";
import ClientDataService from "../../service/ClientDataService"
import FileConfigDataService from "../../service/FileConfigDataService"
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import RightContainer from "../global/RightContainer";
import FileTypeDataService from "../../service/FileTypeDataService"

import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import moment from 'moment';
import LoadingButton from '@mui/lab/LoadingButton';

import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

dayjs.extend(utc);
dayjs.extend(timezone);


function parse(str) {
    if (!/^(\d){8}$/.test(str)) return "invalid date";
    var y = str.substring(0, 4),
        m = str.substring(4, 6),
        d = str.substring(6, 8);
    return new Date(y, m - 1, d);
}

function yyyymmdd(date) {
    var x = new Date(date)
    var y = x.getFullYear().toString();
    var m = (x.getMonth() + 1).toString();
    var d = x.getDate().toString();
    (d.length == 1) && (d = '0' + d);
    (m.length == 1) && (m = '0' + m);
    var yyyymmdd = y + m + d;
    return yyyymmdd;
}


const AssignDateToFile = () => {

    const [warning, setWarnig] = useState(false);
    const [warningMessage, setWarnigMessage] = useState('');

    const handleClose = () => {
        setWarnig(false);
    };

    const [uploading, setUploading] = useState(false);

    const [affliate_open, affliateSetOpen] = useState(false);
    const [affiliate_Options, setAffiliateOptions] = useState([]);

    const [client_open, clientSetOpen] = useState(false);
    const [client_Options, setClientOptions] = useState([]);

    const [network_open, networkSetOpen] = React.useState(false);
    const [networkOptions, setNetworkOptions] = React.useState([]);
    const [filteredNetworkOptions, setFilteredNetworkOptions] = React.useState([]);
    
    const [fileTypes, setFileTypes] = React.useState([]);


    const [startDate, setStartDate] = React.useState()
    const [endDate, setEndDate] = React.useState()
    const [currentFileConfig, setCurrentFileConfig] = React.useState()

    const [selectedIndex, setSelectedIndex] = React.useState(1);
    const [currentParticipantType, setCurrentParticipantType] = React.useState("");
    const [isLoading, setIsLoading] = React.useState(false);

    const [messages, setMessages] = useState([]);
    const [fileConfigs, setFileConfigs] = React.useState({
        column: [
            { field: 'filenm', headerName: 'File Name', width: 250 },
            { field: 'filetp', headerName: 'File Type', width: 150 },
        ],
        data:[]
    });

	const userKey = JSON.parse(localStorage.getItem('userKey') || '0');

    React.useEffect(() => {
		ClientDataService.getAffiliates(userKey).then((res) => {
			return res.data;
		}).then(affliates => {
			affliateSetOpen(true)
			setAffiliateOptions(affliates);
		}).catch(e => {
			console.log(e)
		});

		ClientDataService.getUserNetworks(userKey).then((res) => {
			setNetworkOptions(res.data);
			networkSetOpen(true)
		}).catch(e => {
			console.log(e)
		});

		ClientDataService.getClients(userKey).then((res) => {
			return res.data;
		}).then(clients => {
			clientSetOpen(true);
			setClientOptions(clients);
		}).catch(e => {
			console.log(e)
		});

        FileTypeDataService.getAll().then((res) => {
            setFileTypes(res.data);
        }).catch(e => {
            console.log(e)
        });

    }, [currentFileConfig]);

    const isNonMobile = useMediaQuery("(min-width:600px)");

  
    const handleFormSubmit = (values) => {

        if (!values.client && !values.affiliate) {
            setWarnig(true);
            setWarnigMessage("please select client, network, or affiliate")
            return;
        }

        try {

            var clientKey; 

            if (values.client) {
                clientKey = values.client.clientKey;
                setCurrentParticipantType("Client");
            } else {
                clientKey = values.affiliate.clientKey;
                setCurrentParticipantType("Affiliate");
            }

            FileConfigDataService
                .getFilesByClientOrAffiliate(clientKey)
                .then(res => {
                    console.log(res.data)
                    setFileConfigs({ ...fileConfigs, data: res.data });
                    //setFileConfigs(Object.groupBy(res.data, x => x.participant.systemclientkey))
                }

                ).catch(err => console.log(err))

        } catch (err) {
            console.log(err);

        }
    };


    const deleteFile = () => { 
        let data = fileConfigs.data;
        data = data.filter((item) => item.id !== currentFileConfig.fileconfigkey);
        setFileConfigs({ ...fileConfigs, data: data });
    }

    const ApplySchemaDate = () => {
        if (!currentFileConfig || !currentFileConfig.filedatefrom || !currentFileConfig.filedateto) {
            alert("Please select the start date and end date.");
            return;
        } 

        setIsLoading(true);
        FileConfigDataService
            .update(currentFileConfig.fileconfigkey, currentFileConfig).then((res) => {
                setIsLoading(false);
                setCurrentFileConfig({ ...currentFileConfig, filedatefrom: null, filedateto :null});
                alert("Apply Schema Date successfully.");
                deleteFile();
            })
            .catch(err => console.log(err))
    }

    const selectFileOnClick = (params) => {
        //console.log(params)
        setCurrentFileConfig(params.row.fc);
    }

    const initialValues = {
        client: null,
        affiliate: null,
        network:null,
        participantType: null,
        fileType: null
    };



    return (
        <Stack>
            <RightContainer title="Assign Dates To File" >
                <div>
                    <Dialog
                        open={warning}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {"Invalid input !!!"}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                {warningMessage}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} autoFocus>OK</Button>
                        </DialogActions>
                    </Dialog>
                </div>

                <Box m="20px">
                    <Formik initialValues={initialValues} onSubmit={handleFormSubmit}>
                        {({ handleSubmit, setFieldValue, handleChange, values }) => (
                            <form onSubmit={(e) => {
                                e.preventDefault();
                                handleSubmit();
                            }}>
                                <Box>

                                    <Stack direction="row" spacing={5}>

                                        <Autocomplete
                                            disablePortal
                                            name="participantType"
                                            id="participantType"
                                            value={values.participantType}
                                            sx={{ width: 300 }}
                                            onChange={(event, value) => {
                                                setFieldValue("participantType", value)
                                            }}
                                            options={["Client", "Affliate"]}
                                            renderInput={(params) => <TextField {...params} label="Participant Type" />}
                                            size="small"
                                        />

                                        <br />
                                        {values.participantType == "Client" && <div>
                                            <Autocomplete
                                                size="small"
                                                name="client"
                                                disablePortal
                                                id="client"
                                                value={values.client}
                                                getOptionLabel={(option) => option.qbclientkey + " - " + option.clientName}
                                                options={client_Options}
                                                onChange={(event, value) => {
                                                    setFieldValue("client", value !== null ? value : initialValues.client)
                                                    setFieldValue("affiliate", null)
                                                    //setFilteredNetworkOptions(networkOptions.filter(x => x.systemclientkey == value.clientKey)) 
                                                }}
                                                sx={{ width: 300 }}
                                                renderInput={(params) => <TextField {...params} label="Client" />}
                                            />
                                            <br />
                                        </div>
                                        }



                                        {values.participantType == "Affliate" && <div>
                                            <Autocomplete
                                                size="small"
                                                name="affiliate"
                                                disablePortal
                                                id="affliate"
                                                value={values.affiliate}
                                                getOptionLabel={(option) => option.qbclientkey + " - " + option.clientName}
                                                options={affiliate_Options}
                                                onChange={(event, value) => {
                                                    setFieldValue("affiliate", value !== null ? value : initialValues.affliate)
                                                    setFieldValue("client", null)
                                                    setFieldValue("network", null)
                                                }}
                                                sx={{ width: 300 }}
                                                renderInput={(params) => <TextField {...params} label="Affliate" />}
                                            />
                                            <br />
                                        </div>
                                        }

                                        {/*<Autocomplete*/}
                                        {/*    disablePortal*/}
                                        {/*    name="fileType"*/}
                                        {/*    id="fileType"*/}
                                        {/*    value={values.fileType}*/}
                                        {/*    getOptionLabel={(option) => option.filedesc}*/}
                                        {/*    onChange={(event, value) => { setFieldValue("fileType", value) }}*/}
                                        {/*    options={fileTypes}*/}
                                        {/*    sx={{ width: 300 }}*/}
                                        {/*    renderInput={(params) => <TextField {...params} label="Type" />}*/}
                                        {/*    size="small"*/}
                                        {/*/>*/}

                                        <br />
                                        <Box justifyContent="end" mt="20px">
                                            <Button type="submit" color="secondary" variant="contained">
                                                <span>Search</span>
                                            </Button>
                                        </Box>
                                    </Stack>
                                </Box>
                                <br />
                            </form>
                        )}
                    </Formik>
                    <Divider />
                </Box>

                <Box m="20px">
                    <Stack direction="row" spacing={5}>
                        <Box m="8px 0 0 0"
                            width="50%">
                            {
                                fileConfigs.data.length > 0 &&
                                <Box>{currentParticipantType ? `${currentParticipantType}: ` : ""} {fileConfigs.data[0].participantname}</Box>
                            }
                            
                            <br />
                            <Box
                                m="8px 0 0 0"
                                width="100%"
                                sx={{
                                    "& .MuiDataGrid-root": {
                                        border: "none",
                                    },
                                    "& .MuiDataGrid-cell": {
                                        borderBottom: "none",
                                    }
                                }}
                                className="overwrite-table"
                            >
                                <DataGrid
                                    autoHeight={true}
                                    rows={fileConfigs.data}
                                    pageSize={10}
                                    columns={fileConfigs.column}
                                    onRowClick={selectFileOnClick}
                                />
                            </Box>
                        </Box>
                        

                    {/*<Box m="8px 0 0 0"*/}
                    {/*    width="50%">*/}
                    {/*    <div class="ul-list w-full">*/}
                    {/*        <ul>*/}
                    {/*            {*/}
                    {/*                Object.keys(fileConfigs).map((clientKey) => (*/}
                    {/*                    <>*/}
                    {/*                        <li class="pl-3" key={`Client-${clientKey}`}> <label>{`Client: ${fileConfigs[clientKey][0].participant.participantname}`}</label></li>*/}
                    {/*                        {*/}
                    {/*                            fileConfigs[clientKey].map((configuration, index) => (*/}
                    {/*                                <ListItemButton*/}
                    {/*                                selected={selectedIndex === index}*/}
                    {/*                                 onClick={(event) => { selectFileOnClick(configuration.fc, index)}}>*/}
                    {/*                                <ListItemText>{configuration.fc.filenm} </ListItemText>*/}
                    {/*                                </ListItemButton>*/}
                    {/*                            )*/}
                    {/*                            )*/}
                    {/*                        }*/}
                    {/*                    </>*/}
                    {/*                ))*/}
                    {/*            }*/}
                    {/*        </ul>*/}
                    {/*    </div>*/}
                    {/*</Box>*/}

                        <Box  style={{marginTop:"100px"}}
                        width="20%">
                        {currentFileConfig &&
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Stack spacing={ 5}>
                                <DatePicker
                                    label="Start Date "

                                  value={currentFileConfig.filedatefrom ? moment(currentFileConfig.filedatefrom) : null}
                                    //value={currentFileConfig.filedatefrom}
                                    onChange={(newValue) => {

                                        currentFileConfig.filedatefrom = newValue
                                    }
                                    }
                                />

                                    <br />


                                <DatePicker
                                            label="endDate"
                                            value={currentFileConfig.filedateto ? moment(currentFileConfig.filedateto) : null}
                                            //value={currentFileConfig.filedateto}
                                            dateFormat="yyyy-MM-dd"
                                            onChange={(newValue) => {
                                           // setEndDate(newValue)
                                            currentFileConfig.filedateto = newValue
                                    }
                                    }
                                   />
                                        <Box justifyContent="end" mt="20px">
                                            <LoadingButton
                                                loading={ isLoading }
                                                variant="contained"
                                                color="secondary"
                                                onClick={ApplySchemaDate}>
                                            
                                                Apply Schema Date
                                            </LoadingButton>
                                     </Box>
                                </Stack>
                                </LocalizationProvider>

                        }
                        </Box>
                    </Stack>
                </Box>


            </RightContainer>
        
            </Stack>
        );
}

export default AssignDateToFile